import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import LoadingIndicator from '../loadingIndicator'
import commaNumber from 'comma-number'
import ModalStore from '../../store/ModalStore'
import moment from 'moment'
import UserStore from 'store/UserStore'

const GAME_MSG = {
  eventPetGift: '휴양햄의 선물',
  newUserEventWater: '신규 보상',
  dailyAttendanceLogin: '출석보상 획득',
  giveWaterBasketFive: '물주기 5회',
  giveWaterBasket: '물주기 1회',
  getWaterdailyAttendance: '매일 출석하기',
  weeklyPurchaseReward: '이번주 3번 구매',
  completeTimeDealScreen: '타임딜 구경',
  timerWater: '우물 획득',
  dailyGiveWaterGet: '어제 준 물',
  dailyGiveWaterRemove: '증발한 물',
  bonusRewardBox: '친구 농장 맞팜상자',
  InvitefriendQuest: '친구초대 완료',
  canceledPurchaseReward: '일반상품 취소',
  dailyAttendanceLoginFinalDate: '전체 출석보상',
  luckydraw: '럭키드로우',
  luckydraw101: '럭키드로우',
  luckydraw102: '럭키드로우',
  luckydraw103: '럭키드로우',
  luckydraw104: '럭키드로우',
  luckydraw105: '럭키드로우',
  luckydraw106: '럭키드로우',
  luckydraw107: '럭키드로우',
  luckydraw108: '럭키드로우',
  giveWaterBasketToGganbu: '친구 농장',
  giveWaterBasketFiveToGganbu: '친구 농장',
  setLevelUpRewardTwo: '레벨업2',
  setLevelUpRewardThree: '레벨업3',
  setLevelUpRewardFour: '레벨업4',
  setLevelUpRewardFive: '레벨업5',
  setLevelUpRewardSix: '레벨업6',
  luckydrawStart: '럭키드로우 플레이',
  waterFromGganbu: '친구 농장',
  puppyGift: '바둑이 선물',
  clearedTutorial: '바둑이 선물',
  newUserEvent: '새친구 환영',
  inviteGganbuQuest: '맞팜 퀘스트',
  cx: '고객센터 지급',
  comebackEvent: '복귀 이벤트',
  checkReviewelse: '생생후기 보기',
  waterFromAlwayzFarm: '올팜 농장',
  newbiePurchaseWaterReward: '팜린이 1,000g 구매',
  canceledPurchaseNewbiePurchaseWaterReward: '팜린이 1,000g 취소',
  alwayzFarm: '맞팜 체험',
  purchasedForGganbu: '맞팜친구 품앗이',
  canceledPurchaseForGganbu: '맞팜친구 품앗이 취소',
  secretScratchReward: '비밀복권 당첨',
  secretScratchRewardPurchase: '비밀복권 구매',
  canceledPurchaseSecretWaterReward: '비밀복권 취소',
  leftGganbuInvite: '다시 돌아온 친구',
  cherryPickPurchase: '특별 구매',
  canceledPurchaseCherryPickPurchaseWaterReward: '특별 구매 취소',
  waterToFertilizer: '물 비료 교환',
  dailyLottery: '타임추첨 당첨',
  waterOneOneBattle: '물 맞짱',
  checkReview: '후기보기',
  dailyWaterPurchaseReward: '일반상품 구매',
  canceledDailyWaterPurchaseReward: '일반상품 구매 취소',
  waterBombReward: '물풍선',
  lotteryDrawReward: '캡슐 뽑기',
  scratchLottoReward: '복권 긁기',
  minigameCompleteReward: '아케이드 완료 보상',
  QRBonus: 'QR 선물',
  rouletteEventReward5: '룰렛 이벤트',
  bombPlanted: '폭탄 설치 보상',
  bombExploded: '폭탄 폭발',
  announcementReward: '공지 보상',
  fashionMission: '스타일 페이지 구경',
  couponUsageNudge: '일석이조 찬스 구매',
  newbieTreasureBoxReward: '보물상자 리워드',
  relayReward1: '릴레이 레벨1 구매',
  relayReward2: '릴레이 레벨2 구매',
  relayReward3: '릴레이 레벨3 구매',
  relayReward4: '릴레이 레벨4 구매',
  relayReward: '릴레이 구매 취소',
  rouletteEventV2: '룰렛 이벤트',
  dealPurchase: '특가 상품 구매',
  timeDealPurchase: '타임특가 구매',
  dealCancel: '특가 상품 취소',
  capsuleEvent: '캡슐 이벤트',
  sellerCouponPurchase: '무한쿠폰 찬스 구매',
  browsingMission: '상품 구경하기',
  searchMission: '상품 검색하기',
  flappyBirdPlay: '플라잉팜 플레이',
  flappyBirdReward: '플라잉팜 보상',
  flappyBirdPurchase: '플라잉팜 구매',
  affiliationItem: '취향저격수 상점 구매',
  dailyWaterInvite: '친구 초대 물받기',
  fromCoffeeBridgePage: '스타터팩 지원',
  newyearGift: '설날 선물',
  drinkWater: '물 마시기',
  sidepickDailyReward: '이쪽저쪽 보상',
  sidepickDailyRewardBrag: '이쪽저쪽 자랑하기',
  purchaseRewardSeasonDeal: '특가 상품 구매 취소',
  phoneBookGganbu: '맞팜 초대',
  checkedPhoneBookGganbu: '맞팜 찾아보기',
  pokeSuccess: '콕! 찌르기 보상',
  manbogiStorePurchase: '만보기 상점 교환',
  timeCapsuleBonus: '타임캡슐 보상',
  penaltyRestore: '주문 취소 철회 지급',
  gganbuGift: '맞팜 친구에게 선물',
  photoReviewReview: '사진후기 보상',
  newGiveWaterBasket: '물주기',
  alfarmHarvestOtionEvent: '이벤트 옵션 구매',
  pushAgree: '마케팅 수신 동의',
  alfarmRoulette: '룰렛 뽑기',
  alfarmBugReward: '올팜 구매 보상',
  clearedPurchasedCheckInReward: '연속 출석 기념 보상',
  canceledPurchaseCheckIn: '연속 출석 기념 보상 취소',
  lastHarvestPurchase: '왕창수확 구매',
  solvedQuiz: '올팜 퀴즈',
  quizPurchase: '퀴즈 특가 구매',
  cancelQuizPurchase: '퀴즈 특가 구매 취소',
  alfarmbrowsingAdQuest: '15초 보기 구매',
  cancelBrowsingAdQuestPurchase: '15초 보기 구매 취소',
  sellerStorePurchase: '맞팜 스토어 구매',
  cancelSellerStorePurchase: '맞팜 스토어 구매 취소',
  waterFromSellerFarm: '맞팜 스토어 물받기',
  browsingBrandDealQuest: '브랜드딜 보기',
  browsingWinnerDealQuest: '특가상품 구경하기',
  browsingAdQuest: '상품 보기',
  dailyCheckInExchange: '출석체크 교환소',
  purchaseRoulette: '깜짝 룰렛',
  InvitefriendQuestJoin: '친구 초대 참여(30g)',
  personalizedShop: '선물상자 30초 구경',
  personalizedShopPurchase: '선물상자 구매',
  cancelPersonalizedShop: '선물상자 구매 취소',
  cancelPurchaseRoulette: '깜짝룰렛 구매 취소',
  guessMeCorrectAnswer: '나를 맞혀봐 정답 보상',
  guessMeSbumit: '나를 맞혀봐 플레이',
  selectOnePurchase: '골라사기 구매',
  cancelSelectOnePurchase: '골라사기 구매 취소',
  allBrandDealPurchase: '브랜드 상품 구매',
  cancelPurchaseAllBrandDeal: '브랜드 상품 구매 취소',
  trendQuiz: '우주 최저가 퀴즈',
  eggBreakserBrowsing: '포춘쿠키 상품 보기',
  treasureDealPurchase: '보물특가 구매',
  cancelTreasureDealPurchase: '보물특가 구매 취소',
  eggBreakersPurchase: '포춘쿠키 구매',
  cancelEggBreakersPurchase: '포춘쿠키 구매 취소',
  trendQuizPurchase: '우주 최저가 퀴즈 구매',
  cancelTrendQuizPurchase: '우주 최저가 퀴즈 구매 취소',
  gganbuSurprisePurchaseBrowsing: '맞팜 히든 미션 보상',
  gganbuSurprisePurchase: '맞팜 히든 미션 구매',
  waterBombRewardCost: '물풍선 플레이',
  lotteryDrawRewardCost: '캡슐뽑기 플레이',
  quizPurchaseBrowsing: '퀴즈상품 구경하기',
  waterBrowsingAdQuest: '특별상품 구경하기',
  waterBrowsingAdQuestPurchase: '특별상품 구매',
  tenMinDealScreen: '10분 핫딜 구매',
  cancelTenMinDeal: '10분 핫딜 구매 취소',
  browsingTenMinDealQuest: '10분 핫딜 구경하기',
  updateTenMinDealDailyAttendance: '10분 핫딜 출석',
  dailyMissionQuiz: '매일도전 퀴즈',
  dailyMissionTimeDealQuest: '매일도전 타임딜',
  dailyMissionAdItemBrowsingFert: '매일도전 비료받기 상품 구경',
  dailyMissionPurchase: '매일도전 상품 구매',
  dealItemShare: '공유하기 (하루 최대 100g)',
  goldFilled: '골드필드 구매',
  jewelryRevenueDeal: '주얼리특가 구매',
  kidsRevenueDeal: '키즈특가 구매',
  winterRevenueDeal: '겨울특가 구매',
  snackRevenueDeal: '간식특가 구매',
  daisoRevenueDeal: '생활특가 구매',
  healthRevenueDeal: '건강특가 구매',
  gganbuFarmBrandDeal: '맞팜 브랜드딜 구매',
  lotteryDrawPurchase: '캡슐뽑기 특별구매',
  cancelLotteryDrawPurchase: '캡슐뽑기 특별구매 취소',
  waterTankKakaoShare: '물탱크 공유',
  brandingVideoShare: '영상 공유',
  bottomSheetPurchaseQuestWater: '매일 구매 혜택',
  livingExpensedDeal: '생활비 특가 구매',
  lotteryDrawRewardSpecialPurchase: '특별 구매',
  timerWaterUpgradePurchase: '우물 강화',
  yutNoriPurchase: '윷 던지기 구매',
  yutNoriGame: '윷 던지기',
  cancelYutNoriPurchase: '윷 던지기 구매 취소',
  dailyMissionV2: '매일 도전',
  feverMode: '피버모드 플레이',
  luckyBag: '복주머니 교환',
  newYearPurchase: '복주머니 상품 구매',
  dailyPurchaseQuestWater: '매일 구매 혜택 취소',
  alphabetEvent: '알파벳 이벤트',
  alphabetExchange: '알파벳 교환',
  weeklyPurchaseQuestWater: '주간 구매 혜택',
  weeklyPurchaseQuestFertilizerCancel: '주간 구매 혜택 회수',
  kindergardenPetGift: '유치원햄의 선물',
  resurrectBonus: '복귀기념 선물',
  lastNewyearcheckin: '한정 이벤트 출석하기',
  lastNewyearbrowsing: '한정 이벤트 구경하기',
  lastNewyearpurchase: '한정 이벤트 구매하기',
  newUserValueIndicate: '신규 농부님 지원금',
  jangbogiCoin: '설특가전 교환',
  groceryDeal: '가정의달 상품 구매',
  quitTeamAlfarm: '팀농장 회수',
  giveWaterTeamAlfarm: '팀농장 물주기',
  waterExceeded: '물 넘침',
  alfarmSingleItemDeal: '특가 상품 구매',
  canceledAlfarmSingleItemDeal: '특가 상품 취소',
  cancelNutrimentLeaves: '낙엽 상품 구매 취소',
  nutrimentPurchase: '낙엽 상품 구매',
  resurrectD1Nudge: '이벤트 보상',
  browsingBannerAd: '기획전 특가 구경하기',
  winnerDealBrowsing: '오늘의 특가 구경하기',
  ALFARM_WATER_MISSION_REWARD: '광고 보기',
  fightingGganbu: '맞팜 응원하기',
  ALFARM_WATER_DAILY_MISSION_REWARD: '광고 보기',
  ALFARM_EGG_BREAKERS_MISSION_REWARD: '포춘쿠키 광고 보기',
  ALFARM_YUTNORI_MISSION_REWARD: '윷놀이 광고 보기',
  ALFARM_QUIZ_MISSION_REWARD: '퀴즈 광고 보기',
  ALFARM_WINNER_DEAL_MISSION_REWARD: '오늘의 특가 광고 보기',
  AlfarmDailyAppPushWater: '오늘의 알림 선물',
  SanSinFirstReward: '산신령 이벤트 선물',
  SanSinSecondReward: '산신령 이벤트 선물',
  alfarmStartAlranch: '올목장 시작하기',
  alfarmGiveFeed: '올목장 사료주기',
  alfarmGiveVitamin: '올목장 영양제주기',
  kakaoShareWater: '카카오톡 공유하기',
  offerwallWater: '미션하고 물 받기',
  offerwallReward: '미션하고 보상 받기',
  getWaterDailyCPC: '광고 보기',
  alranchReward: '올목장 경험치 이전',
  mysteryFlowerUse: '신비한 화분에 물주기',
  mysteryFlowerReward: '신비한 화분 보상',
  mysteryFlowerRewardAd: '신비한 화분 광고시청',
  closeGganbuStamp: '맞팜도장 종료',
  recommendedProductAdBrowsing: '추천 상품 구경하기',
  recommendedProductAdGoodsBrowsing: '추천 상품 구경하기',
  recommendedProductAdVideoBrowsing: '추천 영상 구경하기',
  recommendedProductAdPurchase: '추천 상품 구매 보상',
  cancelRecommendedProductAdPurchase: '추천 상품 구매 취소',
  todayDealAdGoodsBrowsing: '오늘의 특가 광고 보기',
  todayDealAdVideoBrowsing: '오늘의 특가 영상 광고 보기',
  todayDealAdPurchase: '오늘의 특가 광고 구매 보상',
  cancelTodayDealAdPurchase: '오늘의 특가 광고 구매 취소',
  yutnoriAdGoodsBrowsing: '윷놀이 광고 보기',
  yutnoriAdVideoBrowsing: '윷놀이 영상 광고 보기',
  yutnoriAdPurchase: '윷놀이 광고 구매 보상',
  cancelYutnoriAdPurchase: '윷놀이 광고 구매 취소',
  fortuneCookieAdGoodsBrowsing: '포춘쿠키 광고 보기',
  fortuneCookieAdVideoBrowsing: '포춘쿠키 영상 광고 보기',
  fortuneCookieAdPurchase: '포춘쿠키 광고 구매 보상',
  cancelFortuneCookieAdPurchase: '포춘쿠키 광고 구매 취소',
  leavesAdGoodsBrowsing: '낙엽 광고 보기',
  leavesAdVideoBrowsing: '낙엽 영상 광고 보기',
  leavesAdPurchase: '낙엽 광고 구매 보상',
  cancelLeavesAdPurchase: '낙엽 광고 구매 취소',
  quizAdGoodsBrowsing: '퀴즈 광고 보기',
  quizAdVideoBrowsing: '퀴즈 영상 광고 보기',
  quizAdPurchase: '퀴즈 광고 구매 보상',
  cancelQuizAdPurchase: '퀴즈 광고 구매 취소',
  doubleTimerWater: '광고보고 우물 획득',
  browsingCPCVAd: '페이지 구경하기',
  drama_bugReward: '숏드라마 미션 보상',
  badahamV2_water: '바다햄 물 보상',
  badahamBrowsing: '바다햄 상품 구매',
  badahamBrowsingCancel: '바다햄 상품 구매 취소',
  waterBrowsingAdGoodsBrowsing: '상품 30초 구경하기',
  waterBrowsingAdGoodsPurchase: '상품 30초 구경하기 구매 보상',
  waterBrowsingAdGoodsCancel: '상품 30초 구경하기 구매 취소',
  waterPurchaseAdPurchase: '상품 구매하기 보상',
  waterPurchaseAdCancel: '상품 구매하기 보상 취소',
  browsingMissionWater: '상품 구경하기',
  kbankWater: '케이뱅크 보상',
  bluebird: '파랑새 복권긁기',
  trendQuizBrowsing: '우주최저가 구경하기',
}

const WaterHistoryModal = observer(({ token }) => {
  const [waterHistoryData, setWaterHistoryData] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)
  const is60CherryPickingUser = UserStore.is60CherryPickingUser

  const getWaterHistory = async () => {
    const result = await backendApis.getWaterHistory(token)

    if (result?.data?.length === 0) {
      setEmptyModal(true)
    }
    let description
    const customData = result?.data?.map((item) => {
      const typeList = item.type.split('_')
      const needFullDescription = typeList?.[0] === 'badahamV2'

      description = needFullDescription
        ? GAME_MSG[item.type]
        : GAME_MSG[typeList?.[0]] || '기타'
      // 이거 _가 들어간 히스토리가 있어서 일단 땜질
      if (typeList?.length >= 4) {
        description = '광고 보기'
      }

      const invited = typeList?.includes('invited') ? ' (친구)' : ''
      description += invited

      const prefix = typeList?.includes('cancel') ? ' 취소' : ''
      description += prefix
      return { ...item, description }
    })

    setWaterHistoryData(customData)
  }

  useEffect(() => {
    getWaterHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 1001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 22,
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          물 기록
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '93vw',
          }}
        >
          <div>
            {waterHistoryData?.length > 0 &&
              waterHistoryData?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        <div
                          style={{
                            color: '#5F2D0C',
                            zIndex: 1999,
                            fontFamily: 'maplestory',
                            fontSize: 16,
                            marginTop: 8,
                            marginRight: 16,
                          }}
                        >
                          {item?.description}
                        </div>
                        <div
                          style={{
                            color: '#855C36',
                            fontFamily: 'maplestory',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                        </div>
                      </div>
                      {item?.action === 'plus' ? (
                        <div
                          style={{
                            color: '#EE7B63',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 18,
                            display: 'flex',
                            flex: 1,
                            fontSize: 20,
                            fontFamily: 'maplestory',
                            marginTop: 8,
                          }}
                        >
                          + {commaNumber(item?.water)}g
                          {item?.water >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <img
                                style={{
                                  width: '8vw',
                                }}
                                alt=''
                                src={'/icon/water.png'}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            color: '#EE7B63',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 18,
                            display: 'flex',
                            flex: 1,
                            fontSize: 20,
                            fontFamily: 'maplestory',
                            marginTop: 8,
                          }}
                        >
                          - {commaNumber(item?.water)}g
                          {item?.water >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <img
                                style={{
                                  width: '8vw',
                                }}
                                alt=''
                                src={'/icon/water.png'}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#EDEDED',
                        fontSize: 18,
                        marginTop: 16,
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 1001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>

      {emptyModal && (
        <>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: 18,
              marginTop: '20vw',
              textAlign: 'center',
            }}
          >
            물 기록이 없어요
            <br />
            물을 받거나 사용해보세요!
          </div>
        </>
      )}
    </>
  )
})

export default WaterHistoryModal
