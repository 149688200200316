import { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import RewardInfoBox from './RewardInfoBox'
import ABStore from 'store/ABStore'
import LoadingIndicator from 'comps/loadingIndicator'
import { showRewardAd } from 'utils/rewardAdHelper'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const ScratchLottoBluebirdModal = observer(({ setShowByebyeBluebird }) => {
  const [isLoading, setIsLoading] = useState(false)
  const throttleRef = useRef({})
  const prizeAB = ABStore?.lottoPrizeAB
  const prizeSet =
    prizeAB === 'a'
      ? ['water', 'fertilizer', 'fertilizerSet']
      : [
          'wetWipes',
          'shinsaegae',
          'starbucks',
          'water',
          'fertilizer',
          'fertilizerSet',
        ]

  useEffect(() => {
    if (
      !localStorage.getItem('bluebirdModalOpen') ||
      new Date() - new Date(localStorage.getItem('bluebirdModalOpen')) >
        24 * 60 * 60 * 1000
    ) {
      localStorage.setItem('bluebirdModalOpen', new Date())
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_SCRATCH_LOTTO_BLUEBIRD',
        },
      }),
    )

    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: 'BluebirdModal',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
      needSampling: false,
    })
  }, [])

  // 애니메이션 속도 조절을 위해 분기처리
  const prizeSetForAnim =
    prizeAB === 'a'
      ? [...prizeSet, ...prizeSet, ...prizeSet]
      : [...prizeSet, ...prizeSet]

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 z-[900] bg-black bg-opacity-90 flex flex-col items-center justify-center'>
      <div className='relative w-[80vw] h-[100vw] bg-[#FFF] border-[8px] border-[#FFD4B9] rounded-xl pt-10 pb-4 mt-10 text-[#343434] text-[16px] flex flex-col items-center justify-between text-center'>
        <img
          src='/scratchLotto/modalTitleBird.png'
          className='absolute top-[-30vw]'
        />
        행운의 파랑새가 복권을 물어왔어요!
        <br />
        복권 한 장을 긁을 수 있어요
        <div className='relative w-full h-[135px] flex-none overflow-x-hidden mb-4'>
          <div className='text-[#7D7D7D] text-center text-[15px] font-light leading-[140%] tracking-[-0.2px] mb-4'>
            꽝 없이 모두 당첨
          </div>
          <div className='flex flex-row'>
            <div className='flex flex-row space-x-[4vw] aria-hidden animate-scroll-slow'>
              {prizeSetForAnim.map((elem, index) => (
                <RewardInfoBox key={elem + index} rewardType={elem} />
              ))}
            </div>
          </div>
        </div>
        {/* 복권 긁으러가기 버튼 */}
        <img
          src='/scratchLotto/scratchLottoBirdButton.png'
          className='w-[90%]'
          onClick={() => {
            setIsLoading(true)
            showRewardAd('rewarded', 'ALFARM_SCRATCH_LOTTO_BLUEBIRD')
            setTimeout(() => {
              ModalStore.setIsModalOpen('LotteryTicketScratchBluebirdModal')
            }, 1500)
            AlfarmEventLogger({
              throttleRef,
              locationType: 'ctaButton',
              locationName: 'BluebirdModal',
              eventType: 'clickShowAdButton',
              data: {},
              collection: 'UserAlfarmClickLog',
              needSampling: false,
            })
          }}
        />
        <span
          className='relative w-[80vw] text-[16px] mt-1 underline text-[#464953]'
          onClick={() => {
            localStorage.setItem('bluebirdFlyAway', new Date())
            setShowByebyeBluebird(true)
            setTimeout(() => {
              setShowByebyeBluebird(false)
            }, 1500)
            ModalStore.setIsModalOpen('basic')
          }}
        >
          파랑새 날려보내기
        </span>
        <div className='absolute w-full bottom-[-18vw] z-[140] text-[#9C9DA4] text-center text-[4.5vw]'>
          올웨이즈와는 무관한 광고예요.
          <br />
          파랑새는 6시간 뒤 집으로 돌아가요!
        </div>
      </div>
      <button
        style={{
          width: '50%',
          top: '16%',
          right: '-10%',
          position: 'absolute',
          zIndex: 3,
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          style={{
            zIndex: 101,
            position: 'absolute',
            width: '15%',
            top: '73%',
            left: '50%',
          }}
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
      <LoadingIndicator isLoading={isLoading} />
    </div>
  )
})

export default ScratchLottoBluebirdModal
