import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import commaNumber from 'comma-number'
import indexStore from '../../../store/indexStore'
import ToastStore from 'store/ToastStore'
import timeChecker from 'utils/timeChecker'
import ModalCloseButton from 'comps/atoms/buttons/ModalCloseButton'
import { ModalButton } from 'comps/atoms/buttons/ModalButton'
import LoadingIndicator from 'comps/loadingIndicator'

const TrendQuizModal = observer(({ token, farmData, setFarmData }) => {
  const { ModalStore, UserStore } = indexStore()
  const [quizData, setQuizData] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const [answer, setAnswer] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [resultTitle, setResultTitle] = useState()

  const goToItemScreen = () => {
    window.location.href = `#navigate.${JSON.stringify({
      screen: 'MainStackDItemScreen',
      prop: {
        itemId: quizData?.itemId,
        isFrom: 'Alfarm_trendQuiz',
        enteringComponent: 'Alfarm_trendQuiz',
        initialComponent: 'Alfarm_trendQuiz',
        imageSource:
          'https://assets.ilevit.com/9bd53cfe-4ef9-47c8-88e8-bc228934133c.png',
      },
    })}`
    ModalStore.setIsModalOpen('basic')
  }

  useEffect(() => {
    if (!timeChecker(farmData?.lastTrendQuizSolvedAt)) {
      setResultTitle('퀴즈는 하루 한 번 가능해요')
      setIsSubmitted(true)
    }
  }, [])

  useEffect(() => {
    const fetch = async () => {
      let res = await backendApis.trendQuiz()
      if (res?.data?.msg === 'success') {
        setQuizData(res?.data?.res)
      }
    }
    fetch()
  }, [])

  const submitAnswer = async () => {
    if (!answer.trim().length) {
      ToastStore.toastOn({
        type: 'emoji',
        message: '정답을 입력해주세요',
        duration: 3000,
      })
      return
    }
    setIsSubmitted(true)
    setIsFocused(false)

    let res = await backendApis.trendQuizSubmitAnswer(token, 'PUT', {
      answer: answer.trim(),
    })

    if (res?.data?.status === 200) {
      let tempWater = res?.data?.rewardAmount

      // ModalStore.setIsModalOpen("basic");
      setFarmData((prev) => {
        let temp = { ...prev }
        if (UserStore.waterExceeded) {
          temp.water += Math.round(res?.data?.rewardAmount * 0.5)
          tempWater = Math.round(res?.data?.rewardAmount * 0.5)
        } else {
          temp.water += res?.data?.rewardAmount
        }
        temp.lastTrendQuizSolvedAt = new Date()
        return temp
      })
      // setTrendQuizOn(false);
      setResultTitle(`물 ${commaNumber(tempWater)}g을 획득했어요`)
    } else {
      setResultTitle('아쉽게도 정답이 아니에요')
    }
    setFarmData((prev) => {
      let temp = { ...prev }
      temp.lastTrendQuizSolvedAt = new Date()
      return temp
    })
  }

  if (!quizData) {
    return <LoadingIndicator isLoading />
  }

  return (
    <>
      <div style={styles.background} />
      <div
        style={{
          ...styles.modalFrame,
          backgroundImage: 'url(../trendQuiz/modal-background.png)',
          top: isFocused ? '70%' : '50%',
        }}
      >
        <ModalCloseButton
          onClick={() => {
            setIsFocused(false)
            ModalStore.setIsModalOpen('basic')
          }}
        />
        {isSubmitted ? (
          <div style={styles.modalBody}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '3vw',
              }}
            >
              <div
                style={{
                  color: '#00246A',
                  textAlign: 'center',
                  fontSize: '5.8vw',
                  fontWeight: 'bold',
                }}
              >
                {resultTitle}
              </div>
              <img
                src='/trendQuiz/reward.png'
                className='max-w-[120px] max-h-[120px]'
              />
              <div
                style={{
                  color: '#404040',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '4.6vw',
                }}
              >
                우주최저가에서 상품 구매시
                <br />물 1,000g, 고급비료 5개를 드려요!
              </div>
              <ModalButton position='relative' onClick={goToItemScreen}>
                우주 최저가 상품 보기
              </ModalButton>
            </div>
          </div>
        ) : (
          <div
            style={{
              ...styles.modalBody,
              paddingLeft: '6vw',
              paddingRight: '6vw',
              justifyContent: 'space-between',
              gap: '3vw',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '4.5vw',
                  color: 'black',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                문제를 맞추고 물을 받아보세요!
              </div>
            </div>
            <div>
              <div
                style={{
                  borderRadius: '4vw',
                  background: '#FFEED1',
                  fontSize: '4vw',
                  color: '#402C24',
                  wordBreak: 'keep-all',
                  wordWrap: 'break-word',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '2vw',
                  height: '32vw',
                  overflowY: 'scroll',
                  display: 'flex',
                  paddingLeft: '2vw',
                  textAlign: 'left',
                  alignItems: 'center',
                  justifyContent:
                    quizData?.question?.length < 70 ? 'flex-start' : 'center',
                }}
              >
                {`Q. ${quizData?.question}`}
              </div>
              <input
                style={{
                  alignItems: 'center',
                  width: '100%',
                  height: '10vw',
                  paddingRight: '4vw',
                  paddingLeft: '4vw',
                  borderRadius: '2vw',
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '3.5vw',
                  zIndex: 5,
                }}
                type='text'
                placeholder={`정답을 입력해주세요`}
                value={answer}
                autoFocus={isFocused}
                onFocus={() => {
                  setIsFocused(true)
                }}
                onBlur={() => {
                  setIsFocused(false)
                }}
                onChange={(e) => {
                  setAnswer(e.target.value)
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <button
                style={{
                  marginRight: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  const prop = {
                    screen: 'MainStackDItemScreen',
                    prop: {
                      itemId: quizData?.itemId,
                      isFrom: 'Alfarm_trendQuiz',
                      enteringComponent: 'Alfarm_trendQuiz',
                      initialComponent: 'Alfarm_trendQuiz',
                      imageSource:
                        'https://assets.ilevit.com/3908b722-8218-45e7-aacf-c454c7be7639.png',
                    },
                  }
                  window.location.href = `#navigate.${JSON.stringify(prop)}`
                }}
              >
                <img
                  src='/trendQuiz/hint.png'
                  alt='abc'
                  style={{ width: '13vw' }}
                />
              </button>
              <button
                onClick={() => {
                  submitAnswer()
                }}
                style={{
                  flex: 1,
                  alignContent: 'center',
                  marginLeft: '0vw',
                  width: '100%',
                  height: '12vw',
                  borderRadius: '6vw',
                  color: 'black',
                  backgroundColor: '#9FDA54',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  zIndex: 2000,
                  border: '2px solid #fff',
                }}
              >
                제출하기
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
})

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalFrame: {
    width: '90vw',
    height: '126vw',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    padding: '4vw',
    paddingTop: '20vw',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '22vw',
    alignItems: 'center',
    zIndex: 100,
  },
}

export default TrendQuizModal
