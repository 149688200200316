import { observable } from 'mobx'
import AB from '../utils/ab_v3'
import moment from 'moment'
import TimeStore from './TimeStore'
import UserStore from './UserStore'

const mdIds = [
  '639af76fbdda032653defa77', // 심예슬
  '62e37050fc5f215e10c9ee72', // 심예슬
  '66d51da1fc4176bf8079c9ac', // 이덕규
  '624a566271d05d3e4c35a7ef', // 윤승진
  '642562174d679c4da14aa974', // 손석주
  '661cde7cae157c5ac22eaf90', // 이동규
  '636c4664d2ae331bee0b1a35', // 임진혁
  '663c3386ed965925ca281553', // 김민경
  '61d51df1c12367667b781e68', // 김민경
  '66f8ea4c7b9855542527377d', // 김성연
  '61e8d7551ed88866d728b272', // 김용진
  '663d69a6c63b5decdf64736e', // 유지아
  '61b981a1bf836b26d4cf66e2', // 이지영
]

const ABStore = observable({
  gifticonTester: false,
  teamAlfarmTest: false,
  pesticideRemoveTester: false,
  doubleExpEventTester: false,
  dietProjectTester: false,
  googleAdTester: false,
  shortFormShopTester: false,
  winnerDealTripleExposure: false, // 오늘의특가 최대 노출량 3회 기작 -> 종료
  winnerDealDailyMaxExposureCount: 1,
  winnerDealTimeIntervalBetweenExposure: 6,
  nutrimentLeavesAdTripleExposure: false, // 낙엽 광고 최대 노출량 3회 기작 -> 종료
  leavesAdDailyMaxExposureCount: 2,
  leavesAdTimeIntervalBetweenExposure: 6,
  quizAdTripleExposure: false, // 퀴즈 광고 최대 노출량 3회 기작 -> 종료
  quizAdDailyMaxExposureCount: 2,
  quizAdTimeIntervalBetweenExposure: 6,
  eggAdTripleExposure: false, // 포춘쿠키 광고 최대 노출량 3회 기작 -> 종료
  eggAdDailyMaxExposureCount: 2,
  eggAdTimeIntervalBetweenExposure: 6,
  yutnoriTripleExposure: false, // 윷놀이 광고 최대 노출량 3회 기작 -> 종료
  yutnoriDailyMaxExposureCount: 1,
  yutnoriTimeIntervalBetweenExposure: 6,
  decreaseWinnerDealRewardTime: false,
  winnerDealRewardTime: 0.5,
  isSanSinEventTester: false,
  winnerDealRewardEnhanceTester: false,
  sellerFarmRewardEnhanceTester: false,
  eggRewardEnhanceTester: false,
  quizRewardEnhanceTester: false,
  floatingButtonTester: false,
  gganbuListReloadTest: false,
  admobTester: false,
  selectFarmTestDefault: false,
  giveWaterTutorialTester: false,
  gganbuStampTester: false,
  recommendedProductAdTester: false,
  isCPMItemsShufflingTester: false,
  isCPMAdViewer: false, // 기존 광고 대신 CPM 광고 보는 주요 키
  isFertilizerSetAdWatchTester: false,
  scratchLottoTester: false,
  specialLotteryDrawTester: false,
  specialWaterBombTester: false,

  // testIDs
  testIds: [
    '6243835a9d22e940cecde8a5', //유성
    '627d10bfd430af8ffc940c7a', //지성
    '61a5a1313a1787613ebc7f2f', //소현
    '62271be8b3ef552fb2dd424d', //윤수
    '63fe1548f61c2dfe055de869', //윤수 dev
    '614b747d698b43323956cf2c', //시창
    '65eae5b80d0fb6902606916a', //유성 데브
    '626aa6a6c4d18f4110ecd6cb', //욱
    '65113f8c3dcc855d62e43a8b', //기훈
    '61bbe52d4c1a4b6cd086a13e', //경국
    '640ac63812a1fa12631d5642', //예원
    '66bb78d0be44cba70b50b77b', //은수
    '634cd960677f1e3b8b9ff306', //종근
    '66d2e95858c039e66de7789e', // 승민
    '6246b1c63433802aafeb591f', // 조소현
    '63bfb30b14a835810aacffb3', // 이은지
    '641dbcc308507ead72c47fa5', // 이솔비
    '639fe92e966e76619667d4da', // 김혜인
    '626ec2b1a66592f5c1603f5f', // 방지헌
    '66ebf8f8e4471f4510f47748', // 박용진 dev
    '66b3194aec1855559a43a283', // 박용진
    '6495b2f114a15be93283353e', // 한사라
    '65bafbcf5db92a7b27b554a4', // 김종현
    '6678f90a9a8a97f36d6156eb', // 여민서
    '651105228137d2b41b5d2e93', // 최민웅
    '64d98ee742e2c30efc87d6e5', //김민국
    ...mdIds,
  ],

  initialize({ userId, data }) {
    const gifticonTesterResult = AB(userId, 'gifticonTester0226', [0])
    this.gifticonTester = gifticonTesterResult === 'b'

    const teamAlfarmTestResult = AB(userId, 'teamAlfarmTest', [100])
    this.teamAlfarmTest = teamAlfarmTestResult === 'b'

    const pesticideRemoveTesterResult = AB(userId, 'pesticideRemoveTester', [
      95,
    ])
    this.pesticideRemoveTester = pesticideRemoveTesterResult === 'b'

    const doubleExpTesterResult = AB(userId, 'doubleExpEventTest', [99])
    this.doubleExpEventTester = doubleExpTesterResult === 'b'

    const googleAdTesterResult = AB(userId, 'googleAdTester', [100])
    this.googleAdTester = googleAdTesterResult === 'b'

    const gganbuListReload = AB(userId, 'gganbuListReload', [0])
    this.gganbuListReloadTest = gganbuListReload === 'b'

    const admobAlfarm0814 = AB(userId, 'admobAlfarm0814', [90])
    this.admobTester = admobAlfarm0814 === 'b'

    const DefaultPlantTest = AB(userId, 'defaulyPlantTest', [30])
    this.selectFarmTestDefault = DefaultPlantTest === 'b'

    const giveWaterTutorialTesterResult = AB(
      userId,
      'giveWaterTutorialTester',
      [50],
    )
    this.giveWaterTutorialTester = giveWaterTutorialTesterResult === 'b'

    const shortFormShopTesterResult = AB(
      userId,
      'shortFormShopTest0627',
      [90, 95],
    )
    this.shortFormShopTester = shortFormShopTesterResult

    const recommendedProductAdTesterResult = AB(
      userId,
      'recommendedProductAdTester1105',
      [0, 100],
    )
    this.recommendedProductAdTester = recommendedProductAdTesterResult === 'b'

    const isCPMItemsShufflingTesterResult = AB(
      userId,
      'isCPMItemsShufflingTester1202',
      [0, 100],
    )
    this.isCPMItemsShufflingTester = isCPMItemsShufflingTesterResult === 'b'

    const isCPMAdViewerResult = AB(userId, 'isCPMAdViewer1205', [0, 100])
    // farm.jsx에서 UserStore.codePushVersionFromAlwayzApp에 따라 한 번 더 세팅함
    this.isCPMAdViewer = isCPMAdViewerResult === 'b'

    const isFertilizerSetAdWatchTesterResult = AB(
      userId,
      'isFertilizerSetAdWatchTester1219',
      [5],
    )
    this.isFertilizerSetAdWatchTester =
      isFertilizerSetAdWatchTesterResult === 'b' && !mdIds.includes(userId)

    // 애드몹 보상형 광고 AB
    this.admobAlfarmTester = AB(userId, 'admobAlfarm0814', [95, 100]) === 'b'

    this.winnerDealTripleExposure =
      AB(userId, 'winnerDealTripleExposure0705', [50, 100]) === 'b'
    this.nutrimentLeavesAdTripleExposure =
      AB(userId, 'nutrimentLeavesAdTripleExposure0715', [50, 100]) === 'b'
    this.quizAdTripleExposure =
      AB(userId, 'quizAdTripleExposure0716', [50, 100]) === 'b'
    this.eggAdTripleExposure =
      AB(userId, 'eggAdTripleExposure0716', [50, 100]) === 'b'
    this.yutnoriTripleExposure =
      AB(userId, 'yutnoriTripleExposure0716', [50, 100]) === 'b'
    this.decreaseWinnerDealRewardTime =
      AB(userId, 'decreaseWinnerDealRewardTime0722', [100, 100]) === 'b' // 적용 받는 기작 지웠음 (진입점 이미지, 초)
    this.isSanSinEventTester =
      AB(userId, 'isSanSinEventTester0726', [50, 100]) === 'b'
    this.winnerDealRewardEnhanceTester =
      AB(userId, 'winnerDealRewardEnhanceTester0731', [90, 100]) === 'b' // 0813 전체 배포 완료
    this.sellerFarmRewardEnhanceTester =
      AB(userId, 'sellerFarmRewardEnhanceTester0812', [0, 100]) === 'b' // 08.16 전체 배포 완료
    this.eggRewardEnhanceTester =
      AB(userId, 'eggRewardEnhanceTester0813', [0, 100]) === 'b' // 08.16 전체 배포 완료
    this.quizRewardEnhanceTester =
      AB(userId, 'quizRewardEnhanceTester0814', [0, 100]) === 'b'
    this.floatingButtonTester =
      AB(userId, 'floatingButtonTest1003', [99]) === 'b' ||
      (AB(userId, 'newUserfloatingButton1016', [50]) === 'b' &&
        UserStore?.farmData?.isNewUser === true) // 플로팅버튼(알림화분) 테스트
    this.gganbuStampTester = AB(userId, 'stamp1022', [99]) === 'b' // 맞팜 레저렉트 도장기작 테스트

    this.badaham1107 = AB(userId, 'badaham1107', [5, 100]) === 'b'
    this.lottoPrizeAB = AB(userId, 'scratchLottoPrize1222', [0, 100])
    this.scratchLottoTester =
      this.badaham1107 && AB(userId, 'scratchLotto1222', [2, 100]) === 'b'
    this.specialLotteryDrawTester =
      this.badaham1107 && AB(userId, 'specialLotteryDraw1219', [2, 100]) === 'b'
    this.specialWaterBombTester = this.badaham1107

    // 강제 테스터
    if (this.testIds.includes(userId)) {
      this.gifticonTester = true
      // this.teamAlfarmTest = true
      // this.doubleExpEventTester = true
      this.googleAdTester = true
      this.admobAlfarmTester = true
      this.shortFormShopTester = 'c'
      this.winnerDealTripleExposure = true
      this.nutrimentLeavesAdTripleExposure = true
      this.quizAdTripleExposure = true
      this.eggAdTripleExposure = true
      this.yutnoriTripleExposure = true
      this.decreaseWinnerDealRewardTime = true
      // this.isSanSinEventTester = false
      this.winnerDealRewardEnhanceTester = true
      this.sellerFarmRewardEnhanceTester = true
      this.eggRewardEnhanceTester = true
      this.quizRewardEnhanceTester = true
      this.floatingButtonTester = true
      this.gganbuListReloadTest = true
      this.admobTester = true
      this.selectFarmTestDefault = true
      this.giveWaterTutorialTester = true
      this.gganbuStampTester = true
      this.recommendedProductAdTester = true
      this.isCPMItemsShufflingTester = true
      this.scratchLottoTester = true
      this.specialLotteryDrawTester = true
      this.specialWaterBombTester = true
    }

    // AB에 따라 영향 받는 변수들 스토어 내에서 세팅
    this.winnerDealDailyMaxExposureCount = this.winnerDealTripleExposure ? 3 : 1
    this.winnerDealTimeIntervalBetweenExposure = this.winnerDealTripleExposure
      ? 4
      : 3 // 기존에 3시간만 하단에 노출됐음. 2회 이상 가면 6으로 바꿔주기
    this.leavesAdDailyMaxExposureCount = this.nutrimentLeavesAdTripleExposure
      ? 3
      : 2
    this.leavesAdTimeIntervalBetweenExposure = this
      .nutrimentLeavesAdTripleExposure
      ? 4
      : 6
    this.quizAdDailyMaxExposureCount = this.quizAdTripleExposure ? 3 : 2
    this.quizAdTimeIntervalBetweenExposure = this.quizAdTripleExposure ? 4 : 6
    this.eggAdDailyMaxExposureCount = this.eggAdTripleExposure ? 3 : 2
    this.eggAdTimeIntervalBetweenExposure = this.eggAdTripleExposure ? 4 : 6
    this.yutnoriDailyMaxExposureCount = this.yutnoriTripleExposure ? 3 : 1
    this.yutnoriTimeIntervalBetweenExposure = this.yutnoriTripleExposure ? 4 : 6
    this.winnerDealRewardTime = this.decreaseWinnerDealRewardTime ? 0.25 : 0.5
  },
})

export default ABStore
