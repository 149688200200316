import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'
import backendApis from 'utils/backendApis'

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '윷 던지기',
    navigationModal: CPM_GOODS_MODAL_TYPES.YUTNORI_AD,
    readyModal: CPM_READY_MODAL_TYPES.YUTNORI_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '윷 던지기',
    navigationModal: CPM_VIDEO_MODAL_TYPES.YUTNORI_AD,
    readyModal: CPM_READY_MODAL_TYPES.YUTNORI_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
    readyModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    buttonMainIconImageUrl: '/minigame/yutnoriEntryIcon.webp',
    buttonSubtitleIconType: 'water', // 버튼 내 리워드 아이콘 - water, fertilizer, null
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    textMaxRewardAmount: purchaseConfig.textMaxRewardAmount,
    buttonTitleText: viewerConfig.buttonTitleText,
    navigationModal: viewerConfig.navigationModal,
    readyModal: viewerConfig.readyModal,
    buttonSubtitleText: `최대 ${purchaseConfig.textMaxRewardAmount}g, 하루 ${purchaseConfig.maxViewCount}번 가능`,
  }

  return BUTTON_PROPS
}

// 윷놀이는 보상 선결정 모달이 있음
const getReadyModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const readyModalProps = {
    userPurchaseFrequencyType,
    modalPath: VIEWER_TYPE_AD_CONFIGS[viewerType].navigationModal,
    modalBackgroundImageUrl: '/minigame/yutnori/yutnoriFieldBackground.png',
    buttonText: '윷 던지기',
  }

  return readyModalProps
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = async (userPurchaseFrequencyType) => {
  const yutnoriAdStatus = await backendApis.getCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
  })

  const { rewardAmount, rewardStatus } = yutnoriAdStatus?.data

  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '상품 추천 중',
    FINAL_BUTTON_TEXT: '상품 구경하기',
    MODAL_IMAGE_URL: '/minigame/yutnori/yutnoriFieldBackground.png',
    YUT_IMAGE_DO_URL: '/minigame/yutnori/yutResultDo.png',
    YUT_IMAGE_GAE_URL: '/minigame/yutnori/yutResultGae.png',
    YUT_IMAGE_GEOL_URL: '/minigame/yutnori/yutResultGeol.png',
    YUT_IMAGE_MO_URL: '/minigame/yutnori/yutResultMo.png',
    YUT_IMAGE_YUT_URL: '/minigame/yutnori/yutResultYut.png',
    REWARD_AMOUNT: rewardAmount,
    REWARD_STATUS: rewardStatus,
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' },
    ANIMATION_DURATION: 2300,
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: '윷놀이 상품 구경하기',
    enteringComponent: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    scrollQuestReward: `물 ${rewardAmount}g`,
    buyingReward: '고급 비료 5개, 물 1,000g',
    // NOTE - deprecated
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
    ratio: 99 / 360,
    questRewardText: `초간 구경하면 물을 ${rewardAmount}g 받아요!`,
    isTimerOn: true,
    // NOTE - deprecated
    enabledIntervalTimer: true,
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }

  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
      userPurchaseFrequencyType,
    },
  }
}

export {
  getButtonProps,
  getGoodsModalProps,
  getReadyModalProps,
  getVideoModalProps,
}
