import { BottomSheet } from 'react-spring-bottom-sheet'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import translateOfferwallDescription, {
  translateReward,
} from 'pages/OfferwallPage/_utils/translateOfferwallDescription'
import useOfferwallPage from './useOfferwallPage'
import UserStore from 'store/UserStore'
import OfferwallContentItem from '../OfferwallContentItem'
import { AlwayzTopUpButton } from '../AlwayzTopUpButton'
import { OfferwallHeader } from '../OfferwallHeader'
import { appBridgeSender } from 'utils/appBridgeSender'
import { OfferwallModal } from '../OfferwallModal'
import { Oval as Loader } from 'react-loader-spinner'
import { SORT_OPTIONS } from '../../_constants/sortOptions'
import { useEffect, useRef, useCallback, useState } from 'react'
import { SortButton } from '../SortButton'
import BrowsingTimerSection from 'comps/molecules/BrowsingTimerSection'
import backendApis from 'utils/backendApis'
import OfferwallModalStore from 'store/OfferwallModalStore'
import AlwayzUserStore from 'store/AlwayzUserStore'
// 광고 타입 정의
// QUIZ: 퀴즈를 풀어야 하는 광고
// SOCIAL: SNS 활동이 필요한 광고
// INSTALL: 앱 설치가 필요한 광고
// CLICK: 클릭만 하면 되는 광고

export const FILTER_TYPES = {
  SOCIAL: {
    types: ['cpyoutube', 'cpylike', 'cpinsta', 'cpk', 'cpl'],
    label: 'SNS형',
  },
  QUIZ: {
    types: ['cpq', 'cpqlite'],
    label: '퀴즈형',
  },
  CLICK: {
    types: ['cpm', 'cpc'],
    label: '클릭형',
  },
  INSTALL: {
    types: ['cpa'],
    label: '참여형',
  },
}

const getButtonText = (ad) => {
  if (ad?.isParticipated) {
    if (ad?.reward_condition === 'click') {
      return '참여 완료'
    }
    return '참여 확인중'
  }

  if (ad?.rewardPoint) {
    return `${translateOfferwallDescription(ad.type)}고 보상받기`
  }
  return '이미 참여했거나 현재 참여가 어려워요'
}

const OfferwallPage = observer(() => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const fromFertilizer = searchParams.get('from') === 'fertilizer'
  const autoOpenAdId = searchParams.get('adId')
  const fromBadahamV2 = searchParams.get('from') === 'badahamV2'
  const missionType = searchParams.get('missionType')
  const startTime = searchParams.get('startTime')
  const [isBottomsheetAutoOpen, setIsBottomsheetAutoOpen] = useState(false)
  const [loadingSpinner, setLoadingSpinner] = useState(false)

  const defaultFilter =
    fromBadahamV2 || fromFertilizer
      ? [
          ...FILTER_TYPES.QUIZ.types,
          ...FILTER_TYPES.SOCIAL.types,
          ...FILTER_TYPES.INSTALL.types,
        ]
      : []

  const defaultSortOption = fromFertilizer
    ? SORT_OPTIONS.FAMOUS.value
    : SORT_OPTIONS.RECOMMENDED.value

  const {
    isInitialLoading,
    filter,
    setFilter,
    fetchData,
    onClickAd,
    participateAd,
    bottomsheetData,
    setBottomsheetData,
    isLoading,
    sortOption,
    setSortOption,
    sortedAndFilteredList,
  } = useOfferwallPage({ defaultFilter, defaultSortOption })

  const handleSortOptionChange = (newOption) => {
    setSortOption(newOption)
  }

  async function openAd(itemInfo) {
    const query = {
      campaignId: itemInfo.id,
      payload: itemInfo.payload,
      userId: AlwayzUserStore.alwayzUserData._id,
      uniqueUserId: UserStore.offerwallInfo.uniqueUserId,
      unitId: UserStore.offerwallInfo.unitId,
      ifa: UserStore.offerwallInfo.ifa,
      ifv: UserStore.offerwallInfo.ifv,
      placement: UserStore.offerwallInfo.placement,
      networkName: UserStore.offerwallInfo.networkName,
      rewardPoint: itemInfo?.rewardPoint,
      adId: itemInfo?.id,
    }
    const result = await backendApis.getOfferwallAdBuzzvil(query)
    if (result?.status === 200) {
      setBottomsheetData({
        isOpen: true,
        ad: {
          ...itemInfo,
          action_description: result.data?.action_description,
          landing_url: result.data?.landing_url,
        },
      })
    }
    setLoadingSpinner(false)
  }

  useEffect(() => {
    if (
      autoOpenAdId &&
      sortedAndFilteredList.length > 0 &&
      OfferwallModalStore.currentModal?.open === false &&
      !isBottomsheetAutoOpen
    ) {
      const ad = sortedAndFilteredList.find(
        (ad) => ad.id.toString() === autoOpenAdId,
      )
      if (ad) {
        setLoadingSpinner(true)
        openAd(ad)
      }
      setIsBottomsheetAutoOpen(true)
    }
  }, [
    autoOpenAdId,
    sortedAndFilteredList,
    OfferwallModalStore.currentModal,
    isBottomsheetAutoOpen,
  ])

  const clickInquiry = () => {
    const params = {
      user_id: UserStore.offerwallInfo.uniqueUserId,
      app_id: UserStore.offerwallInfo.appId,
      unit_id: UserStore.offerwallInfo.unitId,
      platform: UserStore.offerwallInfo.platform,
      ifa: UserStore.offerwallInfo.ifa,
      ifv: UserStore.offerwallInfo.ifv,
    }
    // 쿼리 문자열 생성
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')
    const encodedString = btoa(queryString)

    // 최종 URL 생성
    const url = `https://ad.buzzvil.com/offerwall/inquiry?is_first_page=1&show_close_button=0&q=${encodedString}`
    appBridgeSender.goExternalBrowser(url)
  }

  const renderFilterButtons = () => (
    <div className='flex flex-row items-center gap-2 text-sm font-semibold whitespace-nowrap overflow-x-auto'>
      <button
        type='button'
        className={getFilterButtonClass(isFilterActive(undefined))}
        onClick={() => setFilter(defaultFilter)}
      >
        전체
      </button>

      {Object.values(FILTER_TYPES).map((filterType) => {
        if (
          [FILTER_TYPES.CLICK.types].includes(filterType.types) &&
          fromBadahamV2
        ) {
          return null
        }

        return (
          <button
            key={filterType.label}
            type='button'
            className={getFilterButtonClass(isFilterActive(filterType.types))}
            onClick={() => setFilter(filterType.types)}
          >
            {filterType.label}
          </button>
        )
      })}
    </div>
  )

  // 필터 버튼 활성화 여부 체크
  const isFilterActive = (filterTypes) => {
    if (
      filter?.length === 0 ||
      JSON.stringify(filter) === JSON.stringify(defaultFilter)
    ) {
      return filterTypes === undefined
    }

    return filterTypes?.some((type) => filter?.includes(type))
  }

  const getFilterButtonClass = (isActive) => `
    h-8 px-3 duration-200 rounded-lg active:brightness-90 active:scale-95
    flex items-center justify-center
    ${isActive ? 'text-[#57B921] bg-[#E0F7D4]' : 'text-[#5D5F68] bg-[#f5f5f5]'}
  `

  const renderSortAndFilter = () => (
    <div className='flex flex-col mx-6'>
      {renderFilterButtons()}
      {/* 실험종료 */}
      {/* <div className='h-[15px]'></div> */}
      {/* <DailyCheckInSection /> */}
      <div className='h-[15px]'></div>
      <div className='w-full flex justify-end relative'>
        <SortButton
          sortOption={sortOption}
          setSortOption={handleSortOptionChange}
          sortOptions={{
            RECOMMENDED: SORT_OPTIONS.RECOMMENDED,
            REWARD_HIGH: SORT_OPTIONS.REWARD_HIGH,
            REWARD_LOW: SORT_OPTIONS.REWARD_LOW,
          }}
        />
      </div>
    </div>
  )

  // 필터링된 결과가 있는지 확인하는 함수 추가
  const hasFilteredContent = () => {
    if (isInitialLoading) return true
    return sortedAndFilteredList?.length > 0
  }

  const observerRef = useRef()
  const loadMoreRef = useRef()

  const [retryCount, setRetryCount] = useState(0)
  const MAX_RETRY = 5

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries
      if (
        target.isIntersecting &&
        !isLoading &&
        !isInitialLoading &&
        retryCount < MAX_RETRY
      ) {
        fetchData({
          adTypeList: filter?.length > 0 ? filter : 'all',
        })
        setRetryCount((prev) => prev + 1)
      }

      // 최대 시도 횟수 도달시 observer 중단
      if (retryCount >= MAX_RETRY && observerRef.current) {
        observerRef.current.disconnect()
      }
    },
    [isLoading, isInitialLoading, fetchData, filter, retryCount],
  )

  useEffect(() => {
    setRetryCount(0)
  }, [filter])

  useEffect(() => {
    if (isInitialLoading) return

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    })
    observerRef.current = observer

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [handleObserver, isInitialLoading])

  const [isFinished, setIsFinished] = useState(false)

  const handleTimeEnd = () => {
    setIsFinished(true)
    backendApis.clearDailyEventQuest({
      event: 'badahamV2',
      questType: 'offerwallBrowsing',
    })
  }

  const getRemainingTime = () => {
    if (!startTime || missionType !== 'offerwallBrowsing') return 0

    const targetTime = Number(startTime) + 30 * 1000 // 30초
    const currentTime = new Date().getTime()
    const remainingTime = Math.max(
      0,
      Math.floor((targetTime - currentTime) / 1000),
    )

    return remainingTime
  }

  return (
    <div className='h-screen flex flex-col'>
      <OfferwallHeader
        title='미션 참여하기'
        back
        onRightButtonText='문의'
        onRightClick={clickInquiry}
      />

      <div className='pt-[50px] h-screen overflow-y-auto pb-[50px] bg-white'>
        {fromBadahamV2 && missionType === 'offerwallBrowsing' && (
          <div className='bg-white sticky top-[-2px] pb-[16px] z-10'>
            <BrowsingTimerSection
              time={getRemainingTime()}
              isFinished={isFinished}
              isIntervalTimer={true}
              rewardText='물 최대 40g'
              onTimeEnd={handleTimeEnd}
            />
          </div>
        )}

        {/* 오퍼월 인트로 */}
        <img
          src='https://assets.ilevit.com/8ca91338-7055-460e-aaf8-b5ca39892186.png'
          alt=''
        />
        <div className='h-[29px]' />
        {/* 필터 및 정렬 */}
        {renderSortAndFilter()}
        <div className='h-[12px]' />

        {/* 광고 리스트 */}
        {hasFilteredContent() ? (
          <div className='flex flex-col'>
            {sortedAndFilteredList.map((data, index) => (
              <OfferwallContentItem
                key={`${data.id}-${index}`}
                itemInfo={data}
                empty={isInitialLoading}
                icon={false}
                onClick={() => onClickAd(data)}
              />
            ))}

            {/* 무한 스크롤을 위한 관찰 대상 요소 */}
            <div ref={loadMoreRef} className='h-4' />

            {(isInitialLoading || isLoading) && (
              <div className='flex justify-center w-full my-2'>
                <Spinner className={isInitialLoading ? 'my-20' : 'my-2'} />
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col items-center mx-4 my-20 text-center h-80'>
            <div className='text-lg font-semibold'>
              {filter?.length > 0
                ? '해당 유형의 미션이 없어요'
                : '미션을 준비중이에요'}
            </div>
            <div className='text-sm text-[#616161]'>
              {filter?.length > 0 ? (
                <>
                  다른 유형의 미션을 확인하거나
                  <br />
                  새로운 미션을 불러올까요?
                </>
              ) : (
                <>
                  참여할 수 있는 미션을
                  <br />
                  다시 불러올까요?
                </>
              )}
            </div>
            <button
              type='button'
              className='h-8 px-3 mx-auto my-4 text-sm font-semibold duration-200 rounded-lg bg-[#eeeeee] active:brightness-90 active:scale-95 min-w-14'
              onClick={() => {
                fetchData({
                  adTypeList: filter?.length > 0 ? filter : 'all',
                })
              }}
            >
              불러오기
            </button>
          </div>
        )}
        <AlwayzTopUpButton />

        <BottomSheet
          open={bottomsheetData.isOpen}
          onDismiss={() => setBottomsheetData({ isOpen: false, ad: undefined })}
          className='relative z-20'
          snapPoints={({ minHeight, maxHeight }) => [
            minHeight,
            maxHeight * 0.85,
          ]}
        >
          {bottomsheetData?.ad && (
            <div className='mx-5 mt-1'>
              <img
                className='w-full rounded-xl'
                alt='ad_image'
                src={bottomsheetData.ad?.creative?.image_url}
              />
              <div className='my-6 text-xl font-bold'>
                <div className='mb-0 text-[#212121]'>
                  {bottomsheetData.ad?.creative?.title.length > 15
                    ? `${bottomsheetData.ad?.creative?.title.substring(
                        0,
                        15,
                      )}..`
                    : bottomsheetData.ad?.creative?.title}{' '}
                  {translateOfferwallDescription(bottomsheetData.ad.type)}면
                </div>
                <div className='text-[#212121]'>
                  {translateReward(bottomsheetData.ad?.rewardPointInfo) +
                    '드려요'}
                </div>
              </div>
              <div className='p-3 mb-4 text-sm text-[#212121] bg-[#eeeeee] rounded-lg'>
                {bottomsheetData.ad?.action_description
                  ? renderDescription(bottomsheetData.ad?.action_description)
                  : bottomsheetData.ad?.creative?.description}
              </div>
              <div className='mt-8 mb-24'>
                <div className='mb-4 border border-[#f5f5f5]' />
                <div className='mb-2 font-semibold text-[#757575]'>
                  유의사항
                </div>
                <p className='text-sm text-[#757575] text-wrap'>
                  • 받은 재화는 &apos;올팜&apos; 화면에서 확인할 수 있습니다.
                  <br />
                  • 미션을 완료하고 포인트를 받기까지 약 5분 정도 걸릴 수
                  있습니다.
                  <br />
                  • 포인트를 받지 못했다면 상단 &apos;문의&apos;를 통해
                  문의해주시길 부탁드립니다.
                  <br />
                  • 부적절한 방법으로 참여한 경우, 포인트를 받을 수 없거나
                  미션을 이용하지 못할 수 있습니다.
                  <br />• &apos;올팜 오늘의 미션&apos;은 제휴사 혹은 회사 사정에
                  따라 사전고지 없이 변경되거나 종료될 수 있습니다.
                  <br />• 네트워크 장애가 생기면 미션을 완료하고 포인트를 받을
                  때까지 시간이 더 소요될 수 있습니다.
                </p>
              </div>
              <button
                type='button'
                className={`fixed bottom-4 left-0 z-20 right-0 p-3 my-4 w-full text-lg font-semibold rounded-xl whitespace-nowrap
                  ${
                    bottomsheetData.ad?.rewardPoint === 0 ||
                    bottomsheetData.ad?.isParticipated
                      ? 'bg-gray-300 text-[#757575]'
                      : 'bg-red-500 text-white active:brightness-90 active:scale-95 duration-200'
                  }
                `}
                disabled={
                  bottomsheetData.ad?.rewardPoint === 0 ||
                  isLoading ||
                  (bottomsheetData.ad?.isParticipated &&
                    bottomsheetData.ad?.reward_condition === 'click')
                }
                style={{ margin: '0 auto', width: '90%' }}
                onClick={() => participateAd()}
              >
                {getButtonText(bottomsheetData.ad)}
              </button>
            </div>
          )}
        </BottomSheet>
      </div>
      <OfferwallModal />
      {loadingSpinner && (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center'>
          <Loader color='red' height='40' width='40' />
        </div>
      )}
    </div>
  )
})

const renderDescription = (text) =>
  text.split('\n').map((line, index) => (
    <span key={Math.random()} className='text-[#212121]'>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))

const Spinner = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full h-full py-6'>
      <Loader color='red' height='40' width='40' />
    </div>
  )
}

export default OfferwallPage
