import ModalFrame from 'comps/atoms/Modals/ModalFrame'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const BadahamInfoModal = observer(
  ({ isFrom, itemType, token, randomString }) => {
    return (
      <>
        <ModalFrame
          closeButtonStyle={styles.closeButton}
          modalContentStyle={styles.modalContent}
        >
          <div className='flex flex-col items-center px-[29px]'>
            <img
              src='/ads/badahamMission/badahamInfo.png'
              alt='reward'
              className='w-[93%] mt-[4vw]'
            />
            <div className='flex items-center'></div>
            <div className='text-[#6A3615] text-[4vw] '>
              <br />
              · 각 미션 조건을 달성하면 달성한만큼 보상을 받을 수 있어요 <br />
              · 해당 미션과 달성 횟수는 매일 자정 초기화돼요.
              <br />
            </div>
            <div
              className='p-[2vw] bg-[#FFC634] w-[45vw] rounded-[8vw] text-[#6A3615] 
            shadow-[0px_2px_0px_0px_#FFA234,0px_-1px_0px_0px_#FFD874_inset] 
            h-[11vw] flex justify-center items-center text-[4vw] mt-[5vw]'
              onClick={() => {
                ModalStore?.setIsModalOpen('basic')
              }}
            >
              확인
            </div>
          </div>
        </ModalFrame>
      </>
    )
  },
)

const styles = {
  closeButton: {
    position: 'absolute',
    top: '-15vw',
    right: '-3vw',
    width: '8vw',
  },
  modalContent: {
    width: '80vw',
    height: '95vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    background: '#E9FCFF',
    border: '2vw solid #4EB8CA',
    borderRadius: '5vw',
  },
}

export default BadahamInfoModal
