import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import useInterval from '../../utils/customHooks/useInterval'
import backendApis from '../../utils/backendApis'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import ModalStore from '../../store/ModalStore'
import ToastStore from '../../store/ToastStore'
import centerConfetti from '../../json/gifJson/confetti/centerConfetti.json'
import waterBombReward from '../../json/gifJson/waterBomb/reward.json'
import LottieGif from './LottieGif'
import { sleep } from 'utils/utils'
import moment from 'moment'
import LoadingIndicator from 'comps/loadingIndicator'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'
import { showRewardAd } from 'utils/rewardAdHelper'

const useGesture = createUseGesture([dragAction, pinchAction])

const styles = {
  modalWrapper: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.9)',
    touchAction: 'none',
    fontFamily: 'maplestory',
  },
  closeButton: {
    width: '8vw',
    right: 32,
    top: '5vw',
    position: 'absolute',
    zIndex: 999,
  },
  actionButton: (isDisabled) => ({
    background: isDisabled
      ? 'gray'
      : 'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
    width: '60vw',
    height: '14vw',
    marginTop: '2vw',
    borderRadius: 99,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 30,
    zIndex: 9999,
    fontFamily: 'maplestory',
    fontSize: '3.8vw',
  }),
}

const NoticeModal = () => {
  const [isShow, setIsShow] = useState(
    localStorage.getItem('hasSeenWaterBombNotice') === null,
  )
  const onClose = () => {
    localStorage.setItem('hasSeenWaterBombNotice', 'true')
    setIsShow(false)
  }

  if (!isShow) {
    return <></>
  }

  return (
    <div style={{ ...styles.modalWrapper, backgroundColor: 'rgba(0,0,0)' }}>
      <div
        className='pt-16'
        style={{
          width: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -55%)',
          display: 'flex',
          position: 'absolute',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 16,
          paddingBottom: '16%',
          zIndex: 99,
        }}
      >
        <div className='flex justify-end w-[80vw] mb-8'>
          <button style={{ width: '8vw', zIndex: 1000 }} onClick={onClose}>
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
        </div>

        <div className='flex flex-col w-[80vw]'>
          <div className='w-full mb-8'>
            <img src='/minigame/waterBomb/waterBombNotice.png' alt='' />
          </div>
          <button
            onClick={onClose}
            className='w-full py-[3vw] text-center rounded-[2vw] text-[4vw] font-bold text-white h-[14vw] mb-2'
            style={{
              backgroundImage: 'url(/doubleWater/greenButtonBackground.png)',
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              color: '#366600',
            }}
          >
            좋아요!
          </button>
        </div>
      </div>
    </div>
  )
}
const ChooseWaterBombModal = ({ onClose, onSelectNormal, onSelectSpecial }) => {
  const [isLoading, setIsLoading] = useState(false)
  const throttleRef = useRef({})

  useEffect(() => {
    AlfarmEventLogger({
      needSampling: false,
      throttleRef,
      locationType: 'modal',
      locationName: 'ChooseWaterBombModal',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_SPECIAL_WATER_BOMB_REWARD',
        },
      }),
    )
  }, [])
  return (
    <div style={styles.modalWrapper}>
      <div
        className='pt-16'
        style={{
          width: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -55%)',
          display: 'flex',
          position: 'absolute',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 16,
          paddingBottom: '16%',
          zIndex: 99,
        }}
      >
        <div className='flex justify-end w-[80vw] mb-8'>
          <button style={{ width: '8vw', zIndex: 1000 }} onClick={onClose}>
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
        </div>

        <div className='flex flex-col w-[80vw]'>
          <div className='w-full mb-8'>
            <img src='/minigame/waterBomb/chooseWaterBomb.png' alt='' />
            <LottieGif
              animationData={waterBombReward}
              style={{
                position: 'absolute',
                top: '50vw',
                right: '15vw',
                width: '30vw',
              }}
              loop
            />
          </div>
          <button
            onClick={() => {
              setIsLoading(true)
              onSelectSpecial()
            }}
            className='w-full py-[3vw] text-center rounded-[2vw] text-[4vw] font-bold text-white h-[14vw] mb-2'
            style={{
              backgroundImage: 'url(/doubleWater/greenButtonBackground.png)',
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              color: '#366600',
            }}
          >
            {'특별 물풍선 뽑기'}
          </button>
          <button
            onClick={onSelectNormal}
            className='w-full py-[3vw] text-center rounded-[2vw] text-[4vw] font-bold text-white h-[14vw]'
            style={{
              backgroundImage: 'url(/doubleWater/yellowButtonBackground.png)',
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              color: '#9A6609',
            }}
          >
            오늘의 물풍선 뽑기
          </button>
          <div className='absolute w-full bottom-0 z-[140] text-[#9C9DA4] text-center ml-[-10vw] text-[4.5vw]'>
            올웨이즈와는 무관한 광고예요.
          </div>
        </div>
      </div>
      <LoadingIndicator isLoading={isLoading} />
      <NoticeModal />
    </div>
  )
}

const WaterBombGameModal = observer(
  ({
    token,
    farmData,
    setFarmData,
    setUpdateMinigameInfo,
    showRewardToast,
  }) => {
    const specialWaterBombTester =
      ABStore.specialWaterBombTester &&
      UserStore.farmData?.giveWaterCount >= 100

    const [prize, setPrize] = useState(0)
    const [fertilizer, setFertilizer] = useState(null)
    const [threshHold, setThreshHold] = useState(Math.random() * 40 + 20)

    const [triggerFlag, setTriggerFlag] = useState(false)
    const [isShakeOn, setIsShakeOn] = useState(false)
    const [isGameOver, setIsGameOver] = useState(false)
    const [didPop, setDidPop] = useState(false)
    const [showChooseWaterBombModal, setShowChooseWaterBombModal] = useState(
      specialWaterBombTester,
    )
    const [isSpecialWaterBomb, setIsSpecialWaterBomb] = useState(false)
    const [showLottie, setShowLottie] = useState(true)

    const [waterBombSrc, setWaterBombSrc] = useState(
      'url(../minigame/waterBomb/specialWaterBomb.webp)',
    )

    const throttleRef = useRef({})

    const backgroundImage = isSpecialWaterBomb
      ? 'url(../minigame/waterBomb/specialBackground.png)'
      : 'url(../minigame/waterBomb/background.png)'
    const ref = useRef(null)
    const outerRef = useRef(null)

    useGesture(
      {
        onDrag: ({ type, ...rest }) => {},
      },
      {
        target: outerRef,
      },
    )
    useEffect(() => {
      window.addEventListener('contextmenu', (e) => e.preventDefault())
      const handler = (e) => e.preventDefault()
      document.addEventListener('gesturestart', handler)
      document.addEventListener('gesturechange', handler)
      return () => {
        document.removeEventListener('gesturestart', handler)
        document.removeEventListener('gesturechange', handler)
      }
    }, [])

    const codePushVersionAvailable = useCheckCodePushVersion()
    const userCodePushVersion = UserStore.codePushVersionFromAlwayzApp

    const calculatePrize = (isSpecialWaterBomb, currentPrize) => {
      // 일반 물풍선
      if (!isSpecialWaterBomb) {
        return {
          water: Math.ceil(currentPrize),
          fertilizer: null,
        }
      }
      // 최초 실행 시에는 고급 비료 확정 보삼
      const hasNeverPlayedSpecialWaterBomb =
        localStorage.getItem('isPlayedSpecialWaterBomb') === null

      if (hasNeverPlayedSpecialWaterBomb) {
        localStorage.setItem('isPlayedSpecialWaterBomb', 'true')
        return {
          water: Math.min(Math.ceil(currentPrize), 100),
          fertilizer: {
            type: 'fertilizerSet',
            amount: 1,
          },
        }
      }

      // 특별 물풍선의 경우 확률 기반 보상 계산
      const randomValue = Math.random() * 100 // 0-100 사이의 랜덤값

      if (randomValue < 5) {
        // 5% 확률: 물 + 고급비료
        return {
          water: Math.min(Math.ceil(currentPrize), 100),
          fertilizer: {
            type: 'fertilizerSet',
            amount: 1,
          },
        }
      } else if (randomValue < 30) {
        // 25% 확률: 물 + 일반비료
        return {
          water: Math.min(Math.ceil(currentPrize), 100),
          fertilizer: {
            type: 'fertilizer',
            amount: 1,
          },
        }
      } else {
        // 70% 확률: 물만 (40~100g, 평균 70g)
        const waterAmount = Math.max(40, Math.min(Math.ceil(currentPrize), 100))
        return {
          water: waterAmount,
          fertilizer: null,
        }
      }
    }

    useEffect(() => {
      if (didPop) {
        setWaterBombSrc('url(../minigame/waterBomb/explode.png)')
      } else {
        setWaterBombSrc(
          isSpecialWaterBomb
            ? 'url(../minigame/waterBomb/specialWaterBomb.webp)'
            : 'url(../minigame/waterBomb/waterBomb.png)',
        )
      }
    }, [didPop, isSpecialWaterBomb])

    async function setWaterBombLastPlayedAt() {
      await backendApis.giveWaterBombReward(token, 'PUT', {
        prize: 0,
      })
      let temp = { ...farmData }
      temp.water += -10
      setFarmData(temp)
      setUpdateMinigameInfo(true)
    }

    useInterval(
      () => {
        if (!isSpecialWaterBomb && prize >= threshHold) {
          setIsShakeOn(false)
          setDidPop(true)
          setIsGameOver(true)
          setWaterBombLastPlayedAt()
        }
        setPrize(Math.round((prize + 0.05) * 100) / 100)
      },
      isShakeOn ? 10 : null,
    )

    // Simulate drag when the element is clicked
    const handleElementClick = () => {
      setShowChooseWaterBombModal(false)
      if (farmData?.water < 10) {
        ToastStore.toastOn({
          type: 'error',
          message: '물이 부족해요! 10g이상 물이 있어야 해요',
          duration: 3000,
        })
        return
      }
      if (!isGameOver) {
        setIsShakeOn(true)
      }
    }

    async function giveWaterBombReward() {
      if (isSpecialWaterBomb || prize < threshHold) {
        const { water, fertilizer } = calculatePrize(isSpecialWaterBomb, prize)
        setPrize(water)
        setFertilizer(fertilizer)

        await backendApis.giveWaterBombReward(token, 'PUT', {
          prize: water,
          fertilizer,
        })
        let temp = { ...farmData }
        temp.water += water - 10
        setFarmData(temp)
      }
      setUpdateMinigameInfo(true)
    }

    // Function to trigger game over and reward logic
    const triggerGameOverAndReward = () => {
      setIsShakeOn(false)
      setIsGameOver(true)
      giveWaterBombReward()
    }

    const randomTimer = async () => {
      const randomDelay = isSpecialWaterBomb
        ? Math.floor(Math.random() * 10000) + 8000
        : Math.floor(Math.random() * 6000) + 2000 // Random delay between 2 to 10 seconds

      await sleep(randomDelay)
      setTriggerFlag(true)
    }

    // Set a random timer to trigger the game over and reward logic
    useEffect(() => {
      if (isShakeOn) {
        randomTimer()
      }
    }, [isShakeOn])

    useEffect(() => {
      if (triggerFlag) {
        triggerGameOverAndReward()
      }
    }, [triggerFlag])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
            touchAction: 'none',
            fontFamily: 'maplestory',
          }}
          ref={outerRef}
        >
          <div
            style={{
              width: '100%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              zIndex: 99,
            }}
          >
            {isShakeOn && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 111,
                  width: '12vw',
                  top: '76%',
                  left: '60%',
                  transform: 'translate(-50%, -50%)',
                }}
                className='slide-out-top'
              >
                <img src='/icon/luckydrawCost.png' alt='' />
              </div>
            )}
            <button
              style={{
                width: '8vw',
                right: 32,
                top: '5vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
                zIndex: 99,
              }}
            >
              {didPop ? (
                <div
                  style={{
                    color: 'black',
                    zIndex: 99,
                    backgroundColor: 'white',
                    padding: '8px 16px 8px 16px',
                    border: '1px solid black',
                    borderRadius: 16,
                    fontWeight: 'bold',
                    position: 'absolute',
                    marginTop: 100,
                    textAlign: 'center',
                  }}
                >
                  앗, 오늘은 꽝이에요 😔
                  <br />
                  아쉽지만 내일 다시 도전해보세요!
                </div>
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    left: '44vw',
                    top: '76vw',
                    fontSize: '1.2rem',
                    zIndex: 99,
                    fontWeight: 'bold',
                    color: 'black',
                    width: '14vw',
                    height: '8vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={isShakeOn ? 'shake-bottom' : ''}
                >
                  <div
                    style={{
                      color: 'black',
                      backgroundColor: '#fffb',
                      padding: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 8,
                    }}
                  >{`${
                    prize < 100 ? prize.toFixed(2) : prize.toFixed(1)
                  }g`}</div>
                </div>
              )}
              <div
                style={{
                  flexDirection: 'column',
                  position: 'absolute',
                  left: '20vw',
                  top: '60vw',
                  fontSize: '0.8rem',
                  zIndex: 97,
                  width: '14vw',
                  height: '8vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ fontWeight: 'bold', color: 'black' }}
                >{`${moment().format('MM월')}`}</div>
                <div
                  style={{ fontWeight: 'bold', color: 'black' }}
                >{`${moment().format('DD일')}`}</div>
              </div>
              <div
                ref={ref}
                style={{
                  width: '50vw',
                  height: '50vw',
                  backgroundImage: waterBombSrc,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  zIndex: 98,
                  marginTop: '0vw',
                  touchAction: 'none',
                }}
                className={isShakeOn ? 'shake-bottom' : ''}
              />

              {isGameOver ? (
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                    width: '60vw',
                    height: '14vw',
                    marginTop: '2vw',
                    borderRadius: 99,
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: 30,
                    zIndex: 9999,
                  }}
                  onClick={() => {
                    if (!didPop) {
                      showRewardToast(
                        Math.ceil(prize),
                        fertilizer
                          ? fertilizer.type === 'fertilizer'
                            ? 'waterAndFertilizer'
                            : 'waterAndFertilizerSet'
                          : 'water',
                      )
                    }
                    ModalStore.setIsModalOpen('basic')
                  }}
                >
                  {didPop
                    ? '닫기'
                    : `물 ${Math.ceil(prize)}g${
                        fertilizer
                          ? ` + ${
                              fertilizer.type === 'fertilizer'
                                ? '일반비료'
                                : '고급비료'
                            } ${fertilizer.amount}개`
                          : ''
                      } 받기`}
                </div>
              ) : (
                <button
                  style={
                    isShakeOn
                      ? {
                          background: 'gray',
                          width: '60vw',
                          height: '14vw',
                          marginTop: '2vw',
                          borderRadius: 99,
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bottom: 30,
                          zIndex: 9999,
                          fontFamily: 'maplestory',
                          fontSize: '3.8vw',
                        }
                      : {
                          background:
                            'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                          width: '60vw',
                          height: '14vw',
                          marginTop: '2vw',
                          borderRadius: 99,
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bottom: 30,
                          zIndex: 9999,
                          fontFamily: 'maplestory',
                          fontSize: '3.8vw',
                        }
                  }
                  disabled={isShakeOn}
                  onClick={handleElementClick}
                >
                  {isShakeOn
                    ? `두구두구.. 보상을 뽑고 있어요`
                    : `오늘의 물풍선 보상 뽑기`}
                </button>
              )}
              <div
                style={{
                  top: '130vw',
                  left: '16%',
                  width: '68vw',
                  display: 'flex',
                  position: 'absolute',

                  color: 'white',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  fontFamily: 'maplestory',
                  fontSize: '3.2vw',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ marginBottom: 4 }}>
                  * 매일 {specialWaterBombTester ? 3 : 1}번, 물 10g으로 물풍선
                  뽑기에 도전해보세요!
                </div>
                <div>
                  {isSpecialWaterBomb ? (
                    <>
                      *특별 물풍선은 최대 물 200g과
                      <br />
                      높은 확률로 일반/고급 비료도 받을 수 있어요
                    </>
                  ) : (
                    '* 오늘의 운에 따라 물을 0~100g까지 받을 수 있어요'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isSpecialWaterBomb && isGameOver && showLottie && (
          <LottieGif
            animationData={centerConfetti}
            className='absolute z-[1000] top-[50vw]'
            onComplete={() => {
              setShowLottie(false)
            }}
          />
        )}

        {showChooseWaterBombModal &&
          UserStore.isRewardAdReady &&
          codePushVersionAvailable(userCodePushVersion, '7.0.27') && (
            <ChooseWaterBombModal
              onClose={() => ModalStore.setIsModalOpen('basic')}
              onSelectNormal={() => {
                handleElementClick()
                AlfarmEventLogger({
                  needSampling: false,
                  throttleRef,
                  locationType: 'modal',
                  locationName: 'ChooseWaterBombModal',
                  eventType: 'click',
                  eventName: 'cta',
                  data: { type: 'normal' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
              onSelectSpecial={async () => {
                showRewardAd('rewarded', 'ALFARM_SPECIAL_WATER_BOMB_REWARD')
                AlfarmEventLogger({
                  needSampling: false,
                  throttleRef,
                  locationType: 'modal',
                  locationName: 'ChooseWaterBombModal',
                  eventType: 'click',
                  eventName: 'cta',
                  data: { type: 'special' },
                  collection: 'UserAlfarmClickLog',
                })
                // 광고가 끝난 후 특별 풍선이 보이도록 기다리기
                setThreshHold(Math.min(Math.random() * 80 + 40, 100))
                await sleep(1000 * 10)
                setIsSpecialWaterBomb(true)
                setShowChooseWaterBombModal(false)
              }}
            />
          )}
      </>
    )
  },
)

export default WaterBombGameModal
