import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../../store/ModalStore'
import { getCPMRecommendedItemsWithDefaults } from '../../../../utils/utils'
import sendToCPMPurchaseScreen from '../../../../utils/sendToCPMPurchaseScreen'
import CPMGoodsModalBodySection from '../../CPMGoodsModalBodySection'
import { getCPMGoodsModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import AB_V3 from 'utils/ab_v3'

import './index.css'

const BrowsingButton = ({
  enteringComponent,
  itemIsLoading,
  disabled,
  buttonText,
  cpmItems,
  cpmPurchaseScreenProps,
  withCancelledAfterActiveCPM,
  abTesterGroup,
  userId,
}) => {
  const isYutnoriAd = enteringComponent === CPM_ENTERING_COMPONENTS.YUTNORI_AD
  const isQuizAd = enteringComponent === CPM_ENTERING_COMPONENTS.QUIZ_AD

  const buttonClasses = `w-[60vw] h-[14vw] rounded-[99px] text-black font-maplestory text-[4.5vw] flex justify-center items-center absolute ${
    isYutnoriAd ? 'bottom-[-22vw]' : 'bottom-[8vw]'
  } left-[10vw]
  ${
    itemIsLoading
      ? 'bg-[#B2B2B2] loading-dots'
      : disabled
      ? 'bg-[#B2B2B2]'
      : 'bg-[#F7C82A]'
  }
  `

  const getCpmPurchaseScreenProps = () => {
    const screenScrollCPM0114Tester = AB_V3(
      userId,
      'screenScrollCPM0114',
      [85, 90, 95, 100],
    )

    if (screenScrollCPM0114Tester === 'a') {
      return cpmPurchaseScreenProps
    }

    if (screenScrollCPM0114Tester === 'b' && isYutnoriAd) {
      return {
        ...cpmPurchaseScreenProps,
        enabledIntervalTimer: false,
        enabledScrollTimer: true,
      }
    }

    if (screenScrollCPM0114Tester === 'c' && isQuizAd) {
      return {
        ...cpmPurchaseScreenProps,
        enabledIntervalTimer: false,
        enabledScrollTimer: true,
      }
    }

    if (screenScrollCPM0114Tester === 'd' && (isYutnoriAd || isQuizAd)) {
      return {
        ...cpmPurchaseScreenProps,
        enabledIntervalTimer: false,
        enabledScrollTimer: true,
      }
    }

    return cpmPurchaseScreenProps
  }

  return (
    <button
      type='button'
      disabled={itemIsLoading || disabled}
      className={`${buttonClasses} active:shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]`}
      onClick={async () => {
        await sendToCPMPurchaseScreen({
          cpmItems,
          props: getCpmPurchaseScreenProps(),
          withCPCCPSAdInCPM: withCancelledAfterActiveCPM,
          abTesterGroup,
        })
      }}
    >
      {buttonText}
    </button>
  )
}

const CPMGoodsModal = observer(
  ({ enteringComponent, userCodePushVersion, userId }) => {
    const [cpmItems, setCpmItems] = useState([])
    const [itemIsLoading, setItemIsLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [buttonText, setButtonText] = useState('')
    const [goodsModalCommonProps, setGoodsModalCommonProps] = useState(null)
    const [cpmPurchaseScreenProps, setCpmPurchaseScreenProps] = useState(null)

    const codePushVersionAvailable = useCheckCodePushVersion()

    const abTesterGroup = 'a'

    const withCancelledAfterActiveCPM =
      abTesterGroup === 'a' ||
      !codePushVersionAvailable(userCodePushVersion, '7.1.42')

    const setModalProps = useCallback(async () => {
      const modalProps = await getCPMGoodsModalPropsByType(enteringComponent)

      setGoodsModalCommonProps(modalProps.GOODS_MODAL_COMMON_PROPS)
      setCpmPurchaseScreenProps(modalProps.CPM_PURCHASE_SCREEN_PROPS)
    }, [enteringComponent])

    const fetchCPMItems = useCallback(async () => {
      if (itemIsLoading) return
      setItemIsLoading(true)

      try {
        await setModalProps()
        const items = await getCPMRecommendedItemsWithDefaults({
          withCancelledAfterActiveCPM,
        })

        setCpmItems(items)

        if (items.length > 0) {
          const modalProps = await getCPMGoodsModalPropsByType(
            enteringComponent,
          )
          setButtonText(
            modalProps.GOODS_MODAL_COMMON_PROPS?.FINAL_BUTTON_TEXT ||
              '구경하기',
          )
        } else {
          setButtonText('오늘 상품이 마감됐어요')
          setDisabled(true)
        }
      } catch (error) {
        console.error('Failed to fetch CPM items:', error)
        setButtonText('오류가 발생했습니다')
      } finally {
        setItemIsLoading(false)
      }
    }, [
      enteringComponent,
      itemIsLoading,
      setModalProps,
      withCancelledAfterActiveCPM,
    ])

    useEffect(() => {
      fetchCPMItems()
    }, [])

    useEffect(() => {
      if (itemIsLoading && goodsModalCommonProps?.FIRST_BUTTON_TEXT) {
        setButtonText(goodsModalCommonProps.FIRST_BUTTON_TEXT)
      }
    }, [itemIsLoading, goodsModalCommonProps])

    const CloseButton = () => {
      const defaultPosition = { top: '5%', right: '5%' }
      const buttonPosition =
        goodsModalCommonProps?.CLOSE_BUTTON_POSITION || defaultPosition

      return (
        <button
          className='w-[10%] absolute z-[1113]'
          style={{
            top: buttonPosition.top,
            right: buttonPosition.right,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            className='absolute z-[101]'
            src='/icon/modalCloseButton.png'
            alt='close modal'
          />
        </button>
      )
    }

    const ModalBodySection = () => {
      const defaultModalProps = {
        MODAL_IMAGE_URL: '',
      }

      return (
        <CPMGoodsModalBodySection
          enteringComponent={enteringComponent}
          goodsModalCommonProps={goodsModalCommonProps || defaultModalProps}
        />
      )
    }

    return (
      <CPMAnimationWrapper
        enteringComponent={enteringComponent}
        animationDuration={goodsModalCommonProps?.ANIMATION_DURATION}
      >
        <div className='flex-1 fixed left-0 top-0 z-[5004] w-full h-screen bg-black/80'>
          {/* 닫기 버튼 */}
          <CloseButton />
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            {/* 모달 이미지 */}
            <ModalBodySection />
            {/* 구경하기 버튼 */}
            <BrowsingButton
              enteringComponent={enteringComponent}
              itemIsLoading={itemIsLoading}
              disabled={disabled}
              buttonText={buttonText}
              cpmItems={cpmItems}
              cpmPurchaseScreenProps={cpmPurchaseScreenProps}
              withCancelledAfterActiveCPM={withCancelledAfterActiveCPM}
              userId={userId}
              abTesterGroup={abTesterGroup}
            />
          </div>
        </div>
      </CPMAnimationWrapper>
    )
  },
)

export default CPMGoodsModal
