import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import timeChecker from 'utils/timeChecker'
import AlfarmAdStore from 'store/AlfarmAdStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import dayjs from 'dayjs'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'

const BRIGHT_GREEN = '#ABE066'
const DARK_GREEN = '#7ED321'
const imageMap = {
  scratchLotto: {
    src: 'https://assets.ilevit.com/45b2423c-4cdd-4615-bc5a-70f1b79972af.png',
    title: '복권 긁기',
    desc:
      ABStore?.lottoPrizeAB === 'b'
        ? '1등은 100만원 상당의 상품?!'
        : '오늘의 행운을 뽑아보세요!',
  },
  luckyDraw: {
    src: '/minigame/luckyDrawEntryIcon.png',
    title: '럭키드로우',
    desc: '다양한 경품들이 기다려요!',
  },
  lotteryDraw: {
    src: '/minigame/lotteryDarwEntryIcon.png',
    title: '캡슐 뽑기',
    desc: '귀여운 캡슐 속에 보상이?!',
  },
  waterBomb: {
    src: '/minigame/waterBombEntryIcon.png',
    title: '물풍선',
    desc: '두근두근, 대박을 향해서!',
  },
  flappyBird: {
    src: '/minigame/flappyBirdEntryIcon.png',
    title: '플라잉팜',
    desc: '아슬아슬 짜릿하게 날아보자!',
  },
  sidepick: {
    src: '/minigame/sidepickEntryIcon.png',
    title: '이쪽저쪽',
    desc: '왼쪽! 오른쪽! 순발력을 발휘하자!',
  },
  tapCount: {
    src: '/minigame/tapCountEntryIcon.png',
    title: '숫자찾기',
    desc: '1부터 20까지 빠르게 눌러보자!',
  },
  yutnori: {
    src: '/minigame/yutnoriEntryIcon.webp',
    title: '윷 던지기',
    desc: '윷을 던지면 물이 최대 50g!',
  },
}

const buttonStyle = {
  background: DARK_GREEN,
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '4vw',
  borderRadius: '2vw',
  paddingTop: '2.5vw',
  paddingBottom: '2.5vw',
  marginRight: '2vw',
  zIndex: 2,
  fontFamily: 'maplestory',
  width: '21%',
}

const playedTodayButtonStyle = {
  right: 0,
  color: '#442b22',
  fontSize: '4vw',
  marginRight: '6vw',
  zIndex: 999,
  fontFamily: 'maplestory',
}
const MAX_WATERBOMB_COUNT = 3

const MinigameItem = observer(
  ({
    type,
    clickFunc,
    isPlayedToday = true,
    farmData = null,
    clearedYutnoriHistory = null,
    waterBombPlayHistory = [],
  }) => {
    const YUTNORI_MAX_AD_COUNT = AlfarmAdStore.yutnoriDailyMaxExposureCount
    const [isClickedButton, setIsClickedButton] = useState(false)
    const [yutnoriAdRemainingCount, setYutnoriAdRemainingCount] =
      useState(YUTNORI_MAX_AD_COUNT)
    const [yutnoriTimeLeft, setYutnoriTimeLeft] = useState(0)

    const specialWaterBombTester =
      ABStore.specialWaterBombTester &&
      UserStore.farmData?.giveWaterCount >= 100

    const fetchYutnoriData = useCallback(() => {
      let intervalId
      const calculateTimeLeft = () => {
        if (!clearedYutnoriHistory) return
        const yutnoriHistory = clearedYutnoriHistory?.filter(
          (x) => !timeChecker(x.clearedAt),
        )
        if (
          yutnoriHistory?.length >= AlfarmAdStore.yutnoriDailyMaxExposureCount
        ) {
          setYutnoriAdRemainingCount(
            YUTNORI_MAX_AD_COUNT - yutnoriHistory.length,
          )
          return
        }

        if (yutnoriHistory) {
          const lastYutnori = yutnoriHistory[yutnoriHistory.length - 1]
          const lastYutnoriDate = new Date(lastYutnori?.clearedAt)
          const now = new Date()
          const diffInSeconds = Math.floor((now - lastYutnoriDate) / 1000)
          const cooldownPeriodInSeconds =
            AlfarmAdStore.yutnoriTimeIntervalBetweenExposure * 60 * 60 // 4 hours in seconds
          let timeLeftInSeconds = cooldownPeriodInSeconds - diffInSeconds

          if (timeLeftInSeconds < 0) {
            timeLeftInSeconds = 0 // Ensure time left doesn't go negative
          }

          setYutnoriTimeLeft(timeLeftInSeconds)
          setYutnoriAdRemainingCount(
            YUTNORI_MAX_AD_COUNT - yutnoriHistory.length,
          )
        }
      }

      calculateTimeLeft() // Initial calculation
      intervalId = setInterval(calculateTimeLeft, 1000) // Update every second

      return () => clearInterval(intervalId) // Cleanup on unmount or farmData change
    }, [clearedYutnoriHistory, isPlayedToday])

    useEffect(() => {
      fetchYutnoriData()
    }, [clearedYutnoriHistory, isPlayedToday])

    const [waterBombTimeLeft, waterBombLeftCount] = useMemo(() => {
      const waterBombHistory = waterBombPlayHistory.filter(
        (x) => !timeChecker(x),
      )
      if (waterBombHistory.length === 0) {
        return [0, MAX_WATERBOMB_COUNT]
      }

      const lastPlayedAt = new Date(
        waterBombHistory[waterBombHistory.length - 1],
      )
      const now = new Date()
      const diffInSeconds = Math.floor((now - lastPlayedAt) / 1000)
      const cooldownPeriodInSeconds = 4 * 60 * 60 // 4 hours in seconds

      return [
        cooldownPeriodInSeconds - diffInSeconds,
        MAX_WATERBOMB_COUNT - waterBombHistory.length,
      ]
    }, [waterBombPlayHistory])

    const handlePointerDown = useCallback(() => {
      setIsClickedButton(true)
    }, [])

    const handlePointerCancel = useCallback(() => {
      setIsClickedButton(false)
    }, [])

    const handlePointerUp = useCallback(() => {
      setIsClickedButton(false)
      if (yutnoriAdRemainingCount <= YUTNORI_MAX_AD_COUNT) {
        clickFunc()
      }
    }, [])

    const PlayButton = useMemo(() => {
      if (
        type === 'yutnori' ||
        (type === 'waterBomb' && specialWaterBombTester)
      ) {
        const buttonTimeLeft =
          type === 'yutnori' ? yutnoriTimeLeft : waterBombTimeLeft
        const buttonCountLeft =
          type === 'yutnori' ? yutnoriAdRemainingCount : waterBombLeftCount

        if (buttonCountLeft <= 0) {
          return (
            <button style={playedTodayButtonStyle}>
              <div
                style={{ color: '#442b22', flexDirection: 'column', flex: 1 }}
              >
                <div
                  style={{ color: '#442b22', flexDirection: 'column', flex: 1 }}
                >
                  <div style={{ color: '#442b22' }}>내일 가능</div>
                </div>
              </div>
            </button>
          )
        } else if (buttonTimeLeft > 0) {
          return (
            <div
              className='text-center mr-[3vw]'
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '3.5vw',
                whiteSpace: 'pre-wrap',
              }}
            >
              {'남은시간\n'}
              <WhiteTextTimer
                timeStamp={new Date(dayjs().add(buttonTimeLeft, 's'))}
                timerMinutes={0}
                color='black'
              />
            </div>
          )
        } else {
          return (
            <>
              <button
                style={{
                  ...buttonStyle,
                  background: isClickedButton ? BRIGHT_GREEN : DARK_GREEN,
                }}
                onPointerDown={handlePointerDown}
                onPointerCancel={handlePointerCancel}
                onPointerUp={handlePointerUp}
              >
                플레이
              </button>
              <div
                className='blinking-slow'
                style={{
                  position: 'absolute',
                  background: '#EA3323',
                  borderRadius: '5vw',
                  paddingTop: '1.1vw',
                  paddingBottom: '1.1vw',
                  paddingRight: '1.1vw',
                  paddingLeft: '1.1vw',
                  right: '3.8vw',
                  top: '3.8vw',
                  zIndex: 999,
                }}
              ></div>
            </>
          )
        }
      }

      if (isPlayedToday) {
        return (
          <button style={playedTodayButtonStyle}>
            <div style={{ color: '#442b22', flexDirection: 'column', flex: 1 }}>
              <div
                style={{ color: '#442b22', flexDirection: 'column', flex: 1 }}
              >
                <div style={{ color: '#442b22' }}>내일 가능</div>
              </div>
            </div>
          </button>
        )
      } else {
        return (
          <button
            style={{
              ...buttonStyle,
              background: isClickedButton ? BRIGHT_GREEN : DARK_GREEN,
            }}
            onPointerDown={handlePointerDown}
            onPointerCancel={handlePointerCancel}
            onPointerUp={handlePointerUp}
          >
            플레이
          </button>
        )
      }
    }, [
      type,
      isPlayedToday,
      yutnoriTimeLeft,
      waterBombTimeLeft,
      waterBombLeftCount,
      isClickedButton,
      handlePointerDown,
      handlePointerCancel,
      handlePointerUp,
    ])

    return (
      <div
        style={{
          position: 'relative',
          color: 'black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2vw 4vw 2vw 4vw',
        }}
      >
        <img
          style={{ width: '18vw', height: '18vw' }}
          src={imageMap[type].src}
          alt=''
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            height: 50,
            padding: '0px 2vw 0px 2vw',
            flex: 1,
          }}
        >
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontWeight: 'bold',
              fontSize: '4vw',
            }}
          >
            {imageMap[type].title}
          </div>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '3.5vw',
            }}
          >
            {imageMap[type].desc}
            {type === 'waterBomb' && specialWaterBombTester ? (
              <>
                <br />
                오늘 남은 횟수 : {waterBombLeftCount}
              </>
            ) : (
              ''
            )}
          </div>
          {type === 'yutnori' && (
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '3.5vw',
              }}
            >
              오늘 남은 횟수: {yutnoriAdRemainingCount}
            </div>
          )}
        </div>
        {PlayButton}
      </div>
    )
  },
)

export default MinigameItem
