import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { farmingList } from '../../config'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const LevelUpRewardModal = observer(
  ({
    id,
    level,
    setShowLevelUpRewardModal,
    farmData,
    setFarmData,
    token,
    setAddWater,
    setAddFertilizer,
    loadUserCoupon,
  }) => {
    const [rewardAnim, setRewardAnim] = useState(false)

    const setLevelUpReward = async () => {
      if (level === 1 && farmData?.levelUpRewardOneLog) {
        return false
      }
      if (level === 2 && farmData?.levelUpRewardTwoLog) {
        return false
      }
      if (level === 3 && farmData?.levelUpRewardThreeLog) {
        return false
      }
      if (level === 4 && farmData?.levelUpRewardFourLog) {
        return false
      }
      if (level === 5 && farmData?.levelUpRewardFiveLog) {
        return false
      }
      if (level === 6 && farmData?.levelUpRewardSixLog) {
        return false
      } else {
        const result = await backendApis.setLevelUpReward(token)

        if (result.status === 200) {
          let tmpFarmData = farmData
          if (UserStore.waterExceeded) {
            tmpFarmData.water += Math.round(
              result?.data?.addedValue?.addedWater * 0.5,
            )
            setAddWater(Math.round(result?.data?.addedValue?.addedWater * 0.5))
          } else {
            tmpFarmData.water += result?.data?.addedValue?.addedWater
            setAddWater(result?.data?.addedValue?.addedWater)
          }
          tmpFarmData.fertilizer += result?.data?.addedValue?.addedFertilizer
          setFarmData(tmpFarmData)
          setAddFertilizer(result?.data?.addedValue?.addedFertilizer)
          setRewardAnim(true)
          loadUserCoupon()
        }
      }
    }

    const levelUpRewardMainImage = () => {
      if (level === 6 && farmData?.levelUpRewardSixLog) {
        return (
          <div>
            <div
              style={{
                position: 'fixed',
                zIndex: 11000,
                top: '30%',
                width: '80%',
                left: '10%',
              }}
            >
              <img alt='' src={`/levelUpReward/lastLevel.png`} />
            </div>

            <button
              style={{
                width: '50%',
                top: '22%',
                right: '0%',
                position: 'absolute',
                zIndex: 11113,
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 1101,
                  width: '20%',
                  right: '12vw',
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        )
      }
      if (
        (level === 2 && !farmData?.levelUpRewardTwoLog) ||
        (level === 3 && !farmData?.levelUpRewardThreeLog) ||
        (level === 4 && !farmData?.levelUpRewardFourLog) ||
        (level === 5 && !farmData?.levelUpRewardFiveLog) ||
        (level === 6 && !farmData?.levelUpRewardSixLog)
      ) {
        return (
          <>
            <button
              onClick={() => {
                setLevelUpReward()
                if (level === 2) {
                  ModalStore.setIsModalOpen('review')
                  setShowLevelUpRewardModal(false)
                  ModalStore.setIsAutoAppearModal(false)
                }
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  zIndex: 11001,
                  width: '35vw',
                  top: '10%',
                  left: '32.5%',
                  transform: 'translate(-50%, -50%)',
                }}
                className='scale-up-center'
              >
                {farmingList[id] ? (
                  <img alt='' src={`/farm/${farmingList[id]}/${level}.gif`} />
                ) : null}
              </div>

              <div
                style={{
                  position: 'absolute',
                  zIndex: 1999,
                  top: '20%',
                  left: '5%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                }}
                className='scale-up-hor-center'
              >
                <img src={`/levelUpReward/Ready${level}New.png`} alt='' />

                <button
                  style={{
                    width: '85%',
                    bottom: '0%',
                    height: '15%',
                    left: '7%',
                    borderRadius: 30,
                    zIndex: 1999,
                    position: 'absolute',
                  }}
                  // onClick={() => {
                  //   setLevelUpReward();
                  // }}
                  className='scale-up-center-level-up-button'
                >
                  <img src='/levelUpReward/levelRewardButton.png' alt='' />
                  {level === 2 && !farmData?.levelUpRewardTwoLog && (
                    <img
                      style={{
                        position: 'absolute',
                        width: '15vw',
                        zIndex: 1222,
                        right: '-10vw',
                        top: '10vw',
                      }}
                      src='/icon/finger.png'
                      alt=''
                      className='slide-tl'
                    />
                  )}
                </button>
              </div>
            </button>
          </>
        )
      }
      if (
        level === 1 ||
        (level === 2 && farmData?.levelUpRewardTwoLog) ||
        (level === 3 && farmData?.levelUpRewardThreeLog) ||
        (level === 4 && farmData?.levelUpRewardFourLog) ||
        (level === 5 && farmData?.levelUpRewardFiveLog)
      ) {
        return (
          <div>
            <div>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 11000,
                  top: '8vw',
                  width: '40%',
                  left: '30%',
                }}
              >
                <img alt='' src={`/farm/${farmingList[id]}/${level + 1}.gif`} />
              </div>
              <img
                style={{
                  zIndex: 11999,
                  top: '75vw',
                  width: '90%',
                  left: '5%',
                  position: 'absolute',
                }}
                src={`/levelUpReward/NotYet${level + 1}New.png`}
                alt=''
              />
            </div>
            <button
              style={{
                width: '50%',
                top: '115.5%',
                left: '15%',
                position: 'absolute',
                zIndex: 11113,
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 1101,
                  width: '20%',
                }}
                src='/icon/attendanceWaterCloseButton.png'
                alt=''
              />
            </button>
          </div>
        )
      }
    }

    const rewardAnimationImage = () => {
      if (level === 3) {
        return (
          <div>
            <div
              style={{
                position: 'fixed',
                zIndex: 11000,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '25vw',
              }}
            >
              <img alt='' src='/effect/levelUpRewardMotion.gif' />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: 1999,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
              }}
            >
              <img alt='' src='/icon/levelUpRewardBackGround.png' />
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  zIndex: 1999,
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100vw',
                  fontFamily: 'maplestory',
                  fontSize: '6vw',
                }}
              >
                레벨업 보상을 받았어요!
                <div
                  style={{
                    marginTop: '2vw',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    fontWeight: 'lighter',
                  }}
                >
                  일반비료 1개, 물 100g, 쿠폰 200원
                </div>
              </div>
            </div>
            <button
              style={{
                width: '30%',
                height: '14vw',
                top: '73%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                position: 'absolute',
                zIndex: 1999,
                borderRadius: '40vw',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '4.5vw',
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      }
      if (level === 4) {
        return (
          <div>
            <div
              style={{
                position: 'fixed',
                zIndex: 11000,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '25vw',
              }}
            >
              <img alt='' src='/effect/levelUpRewardMotion.gif' />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: 1999,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
              }}
            >
              <img alt='' src='/icon/levelUpRewardBackGround.png' />
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  zIndex: 1999,
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100vw',
                  fontFamily: 'maplestory',
                  fontSize: '6vw',
                }}
              >
                레벨업 보상을 받았어요!
                <div
                  style={{
                    marginTop: '2vw',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    fontWeight: 'lighter',
                  }}
                >
                  일반비료 2개, 물 100g, 쿠폰 300원
                </div>
              </div>
            </div>
            <button
              style={{
                width: '30%',
                height: 55,
                top: '73%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                position: 'absolute',
                zIndex: 1999,
                borderRadius: 30,
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: 18,
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      }
      if (level === 5) {
        return (
          <div>
            <div
              style={{
                position: 'fixed',
                zIndex: 11000,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '25vw',
              }}
            >
              <img alt='' src='/effect/levelUpRewardMotion.gif' />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: 1999,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
              }}
            >
              <img alt='' src='/icon/levelUpRewardBackGround.png' />
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  zIndex: 1999,
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100vw',
                  fontFamily: 'maplestory',
                  fontSize: '6vw',
                }}
              >
                레벨업 보상을 받았어요!
                <div
                  style={{
                    marginTop: '2vw',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    fontWeight: 'lighter',
                  }}
                >
                  일반비료 2개, 물 100g, 쿠폰 400원
                </div>
              </div>
            </div>
            <button
              style={{
                width: '30%',
                height: 55,
                top: '73%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                position: 'absolute',
                zIndex: 1999,
                borderRadius: 30,
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: 18,
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      }
      if (level === 6) {
        return (
          <div>
            <div
              style={{
                position: 'fixed',
                zIndex: 11000,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '25vw',
              }}
            >
              <img alt='' src='/effect/levelUpRewardMotion.gif' />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: 1999,
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80vw',
              }}
            >
              <img alt='' src='/icon/levelUpRewardBackGround.png' />
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  zIndex: 1999,
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100vw',
                  fontFamily: 'maplestory',
                  fontSize: '6vw',
                }}
              >
                레벨업 보상을 받았어요!
                <div
                  style={{
                    marginTop: '2vw',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    fontWeight: 'lighter',
                  }}
                >
                  일반비료 3개, 물 100g, 쿠폰 500원
                </div>
              </div>
            </div>
            <button
              style={{
                width: '30%',
                height: 55,
                top: '73%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                position: 'absolute',
                zIndex: 1999,
                borderRadius: 30,
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: 18,
              }}
              onClick={() => {
                setShowLevelUpRewardModal(false)
                ModalStore.setIsAutoAppearModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      } else {
        ;<></>
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        />

        {rewardAnim ? (
          <> {rewardAnimationImage()}</>
        ) : (
          <> {levelUpRewardMainImage()}</>
        )}
      </>
    )
  },
)

export default LevelUpRewardModal
