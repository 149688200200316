import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { basicWaterDoubleAB } from 'utils/abTests'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'

const getModalAsset = (basicWaterDouble) => {
  if (basicWaterDouble === 'c') {
    return {
      headerImage: '/doubleWater/header2.png',
      descriptionText: '물을 3배로 받을 수 있어요!',
      mainImage: '/doubleWater/doubleWater2.webp',
      buttonText: '광고보고 물 3배 받기',
    }
  } else if (basicWaterDouble === 'd') {
    return {
      headerImage: '/doubleWater/header3.png',
      descriptionText: '물을 최대 3배로 받을 수 있어요!',
      mainImage: '/doubleWater/doubleWater3.png',
      buttonText: '광고보고 물 최대 3배 받기',
    }
  } else {
    return {
      headerImage: '/doubleWater/header1.png',
      descriptionText: '물을 2배로 받을 수 있어요!',
      mainImage: '/doubleWater/doubleWater1.png',
      buttonText: '광고보고 물 2배 받기',
    }
  }
}
const DoubleWaterModal = observer(
  ({ amount, onConfirm, onCancel, onModalClose }) => {
    const modalAsset = getModalAsset(basicWaterDoubleAB.get())
    const throttleRef = useRef({})
    const [adCheckTimer, setAdCheckTimer] = useState(0)

    // 페이지 뷰 로그
    useEffect(() => {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'prepareRewardedAd',
          data: {
            adType: 'rewarded',
            placement: 'ALFARM_DOUBLE_BASIC_WATER_MISSION_REWARD',
          },
        }),
      )
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: 'DoubleWaterModal',
        eventType: 'pageview',
        data: {
          waterAmount: amount,
        },
        collection: 'UserAlfarmPageviewLog',
      })
    }, [])

    useEffect(() => {
      const timer = setTimeout(() => {
        setAdCheckTimer((prev) => prev + 1)
      }, 1000)
      return () => clearTimeout(timer)
    }, [adCheckTimer])

    const handleConfirmClick = () =>
      UserStore.isRewardAdReady ? onConfirm() : null

    return (
      <div
        className='
    bg-[#000000dd]
     absolute top-[0] left-0 w-full h-full z-[140]'
      >
        <div className='relative flex flex-col top-[-10vw] w-full h-full justify-center items-center'>
          <button
            onClick={onModalClose}
            className='relative w-8 h-8 top-[9vw] left-[35vw]'
          >
            <img src='/icon/close.png' alt='닫기' className='w-full h-full' />
          </button>
          <img
            src={modalAsset.headerImage}
            alt='헤더'
            className='relative w-[75vw] h-[25vw] top-[10vw] z-10'
          />
          {/* </div> */}
          <div
            className='relative bg-white rounded-2xl p-6 w-[80vw] text-center'
            style={{
              border: '8px solid #B8EA82',
            }}
          >
            <div className='text-[4vw] text-[#777777] mt-2 mb-2'>
              아래 버튼을 눌러 광고를 보면
              <br />
              {modalAsset.descriptionText}
            </div>
            <div className='flex justify-center items-center mb-4'>
              <div className='relative'>
                <img
                  src={modalAsset.mainImage}
                  alt='물통'
                  className='w-[55vw]'
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <button
                onClick={handleConfirmClick}
                className='w-full py-[3vw] text-center rounded-[2vw] text-[4vw] font-bold text-white'
                style={{
                  backgroundImage:
                    'url(/doubleWater/greenButtonBackground.png)',
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  overflow: 'auto',
                  color: '#366600',
                }}
              >
                {UserStore.isRewardAdReady ? (
                  modalAsset.buttonText
                ) : adCheckTimer >= 5 ? (
                  '잠시 후에 다시 시도해주세요'
                ) : (
                  <div className='flex justify-center items-center gap-2 '>
                    <div className='text-[#366600] font'>광고 불러오는 중</div>
                    <div className='animate-spin h-5 w-5 border-2 border-green-800 rounded-full border-t-transparent' />
                  </div>
                )}
              </button>
              <button
                onClick={onCancel}
                className='w-full py-[3vw] text-center rounded-[2vw] text-[4vw] font-bold text-white'
                style={{
                  backgroundImage:
                    'url(/doubleWater/yellowButtonBackground.png)',
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  overflow: 'auto',
                  color: '#9A6609',
                }}
              >
                물 {amount}g 받기
              </button>
              <div className='absolute w-full bottom-[-12vw] z-[140] text-[#9C9DA4] text-center ml-[-6vw] text-[4.5vw]'>
                올웨이즈와는 무관한 광고예요.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default DoubleWaterModal
