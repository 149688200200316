import {
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'

export const VIDEO_AD_MODAL_TYPE = {
  todayDealAd_video: 'todayDealAd_video',
  recommendedProductAd_video: 'recommendedProductAd_video',
  quizAd_video: 'quizAd_video',
  leavesAd_video: 'leavesAd_video',
  fortuneCookieAd_video: 'fortuneCookieAd_video',
  yutnoriAd_video: 'yutnoriAd_video',
}

export const VIDEO_AD_TYPE = {
  [CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_TODAY_DEAL_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  [CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_RECOMMENDED_PRODUCT_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  [CPM_ENTERING_COMPONENTS.LEAVES_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_LEAVES_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  [CPM_ENTERING_COMPONENTS.QUIZ_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_QUIZ_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_FORTUNE_COOKIE_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: {
    adType: 'rewarded',
    placement: 'ALFARM_YUTNORI_AD',
    params: {
      userType: CPM_PURCHASE_FREQUENCY_TYPES.LOW,
    },
  },
  ALFARM_BADAHAM_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_BADAHAM_MISSION_REWARD',
  },
  ALFARM_BADAHAM_V2_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_BADAHAM_V2_MISSION_REWARD',
  },
  ALFARM_FERTILIZER_SET_AD_WATCH: {
    adType: 'rewarded',
    placement: 'ALFARM_FERTILIZER_SET_AD_WATCH',
  },
  ALFARM_FERTILIZER_GET_AD_WATCH: {
    adType: 'rewarded',
    placement: 'ALFARM_FERTILIZER_GET_AD_WATCH',
  },
  ALFARM_FERTILIZER_SET_GET_AD_WATCH: {
    adType: 'rewarded',
    placement: 'ALFARM_FERTILIZER_SET_GET_AD_WATCH',
  },
  ALFARM_WATER_DAILY_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_WATER_DAILY_MISSION_REWARD',
  },
  ALFARM_YUTNORI_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_YUTNORI_MISSION_REWARD',
  },
  ALFARM_EGG_BREAKERS_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_EGG_BREAKERS_MISSION_REWARD',
  },
  ALFARM_NUTRIMENT_LEAVES_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_NUTRIMENT_LEAVES_MISSION_REWARD',
  },
  ALFARM_QUIZ_MISSION_REWARD: {
    adType: 'rewarded',
    placement: 'ALFARM_QUIZ_MISSION_REWARD',
  },
}
