export const farmingList = [
  'pear',
  'onion',
  'apple',
  'sweetpotato',
  'tomato',
  'mandarin',
  'persimmon',
  'potato',
  'lemon',
  'carrot',
  'coffee_starbucks',
  'egg',
  'orange',
  'greenOnion',
  'banana',
  'grape',
  'grapefruit',
  'avocado',
  'rice',
  'lime',
  'galic',
  'sprout',
  'asparagus',
  'pepper',
  'pineapple',
  'mool',
  'mychew',
  'coffee_mega',
  'coffee_compose',
  'ramen',
  'pokachip',
  'toothpaste',
  'dishdetergent',
  'toothset',
  'milk',
]

export const farmingListOngoing = [
  // "pear",
  'onion',
  // 'apple',
  'sweetpotato',
  'tomato',
  'potato',
  'lemon',
  'carrot',
  'coffee_starbucks',
  'egg',
  'orange',
  'greenOnion',
  'banana',
  'grape',
  // 'grapefruit',
  'avocado',
  'rice',
  // "lime",
  'galic',
  // 'sprout',
  // 'asparagus',
  // 'pepper',
  // 'pineapple',
  'mool',
  // 'mychew',
  'coffee_mega',
  'coffee_compose',
  // 'ramen',
  // 'pokachip',
  'toothpaste',
  'dishdetergent',
  // 'toothset',
  'milk',
]

export const farmingListForTuto = ['tutorial', 'onion']
