import React from 'react'
import PlantBasicInfo from '../PlantBasicInfo'
import { farmingList } from '../../../config.jsx'

const DummyGganbuFarmComponent = ({ rewardTimeoutRef, setRewardModalInfo }) => {
  return (
    <div className='relative flex justify-between w-[92vw] h-[36vw] border-2 border-white bg-[#FFFFFF80] rounded-2xl text-black mb-[4vw]'>
      <div className='relative'>
        {/* 맞팜 작물 */}

        <div className='relative h-[26vw] w-[26vw] ml-[4vw]'>
          {farmingList[PlantBasicInfo.IdFromPlantName('onion')] ? (
            <img
              className='absolute -top-[12vw]'
              alt=''
              src={`/farm/${
                farmingList[PlantBasicInfo.IdFromPlantName('onion')]
              }/1.gif`}
            />
          ) : null}
        </div>

        {/* 맞팜 프로필이미지와 이름 */}
        <div className='relative flex items-center ml-[4vw]'>
          <div className='absolute -top-[4vw] -right-[0vw] z-[3] px-[1vw] py-[0.5vw] items-center justify-center text-[2vw] text-white font-bold rounded-md bg-[#09090960] bg-[#09090960]'>
            since 2022
          </div>
          <div className='rounded-full border-[0.4vw] border-white z-[2]'>
            <img
              src={'/icon/alwayzIcon.png'}
              alt=''
              className='w-[6vw] h-[6vw] rounded-full'
            />
          </div>
          <div className='absolute left-[3vw] bg-[#09090960] rounded-md w-[23vw] py-[0.5vw] text-center'>
            올웨이즈
          </div>
        </div>

        <div className='absolute top-[10vw] -right-[16vw]'>
          <button
            onClick={() => {
              if (rewardTimeoutRef.current) {
                clearTimeout(rewardTimeoutRef.current)
              }
              setRewardModalInfo({
                modalType: 'reward',
                show: true,
                rewardType: 'gganbuNudge',
                style: {
                  padding: '2vw',
                  fontSize: '4.2vw',
                },
                message: `맞팜이 3명 이상인 농부님들은
                약 8일 빨리 무료 작물을 수확해요!`,
              })
              rewardTimeoutRef.current = setTimeout(() => {
                setRewardModalInfo({
                  modalType: 'reward',
                  show: false,
                  rewardType: '',
                  amount: 0,
                  message: '',
                })
              }, 3000)
            }}
          >
            <img
              className='w-[18vw] h-[18vw]'
              src={`/icon/gganbuFarm/icn_gganbuFarmList_giveWater.png`}
              alt=''
            />
          </button>
        </div>
      </div>
      {/* 상호작용 버튼 영역 */}
      <div className='flex justify-end items-center mt-[2vw] mr-[4vw]'>
        <button
          onClick={() => {
            if (rewardTimeoutRef.current) {
              clearTimeout(rewardTimeoutRef.current)
            }
            setRewardModalInfo({
              modalType: 'reward',
              show: true,
              rewardType: 'water',
              style: {
                padding: '2vw',
                fontSize: '4.2vw',
              },
              message: `맞팜이 있으면
              매일 물을 받을 수 있어요!`,
            })
            rewardTimeoutRef.current = setTimeout(() => {
              setRewardModalInfo({
                modalType: 'reward',
                show: false,
                rewardType: '',
                amount: 0,
                message: '',
              })
            }, 2000)
          }}
        >
          <img
            className='w-[15vw] h-[15vw]'
            src={`/icon/gganbuFarm/icn_gganbuFarmList_getWater.png`}
            alt=''
          />
        </button>
        <button
          onClick={() => {
            if (rewardTimeoutRef.current) {
              clearTimeout(rewardTimeoutRef.current)
            }
            setRewardModalInfo({
              modalType: 'reward',
              show: true,
              rewardType: 'fert',
              style: {
                padding: '2vw',
                fontSize: '4.2vw',
              },
              message: `맞팜이 있으면
              매일 비료를 받을 수 있어요!`,
            })
            rewardTimeoutRef.current = setTimeout(() => {
              setRewardModalInfo({
                modalType: 'reward',
                show: false,
                rewardType: '',
                amount: 0,
                message: '',
              })
            }, 2000)
          }}
        >
          <img
            className='w-[15vw] h-[15vw]'
            src={`/icon/gganbuFarm/icn_gganbuFarmList_getFert.png`}
            alt=''
          />
        </button>
      </div>
    </div>
  )
}

export default DummyGganbuFarmComponent
