import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'

const RouletteModal = observer(
  ({ token, farmData, setFarmData, setShowPuppy }) => {
    const [inviteButtonPressed, setInviteButtonPressed] = useState(false)
    const [rouletteRewardNumber, setRouletteRewardNumber] = useState(0)
    const [rouletteRewardAmount, setRouletteRewardAmount] = useState(0)

    const [isRolling, setIsRolling] = useState(false)
    const [couponCount, setCouponCount] = useState(0)
    const [infoModal, setInfoModal] = useState(false)
    const [rewardModal, setRewardModal] = useState(false)
    const [inviteCount, setInviteCount] = useState(false)
    const [guideModal, setGuideModal] = useState(false)
    const [rewardAmount, setRewardAmount] = useState(5)

    const [canClickStartButton, setCanClickStartButton] = useState(false)
    const REWARD_NAME = [
      '바둑이 1일',
      '고급비료 1개',
      '일반비료 2개',
      '일반비료 1개',
      '물 30g',
      '물 10g',
    ]
    const DEGREE_MAP = [
      5310, //바둑이
      5130, // 고급비료 1개
      5370, //일반비료 2개
      5190, //일반비료 1개
      5070, //물 30G
      5250, //물 10g
    ]

    useEffect(() => {
      checkRouletteEventCouponCount()
      checkDailyInvite()
    }, [])

    const checkDailyInvite = async () => {
      const result = await backendApis.checkRouletteInvite()
      if (result?.status === 200) {
        setInviteCount(result?.data)
      }
    }

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmRouletteInvite',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    const checkRouletteEventCouponCount = async () => {
      const result = await backendApis.checkRouletteEventCouponCount(token)
      if (result?.status === 200) {
        setCouponCount(result?.data)
        if (result?.data >= 1) {
          setCanClickStartButton(true)
        }
      }
    }

    const startRoulette = async () => {
      setCanClickStartButton(false)
      if (couponCount < 1) {
        return
      }
      setIsRolling(true)
      if (canClickStartButton) {
        const result = await backendApis.startRoulette(token)
        setRouletteRewardNumber(result?.data?.reward)
        setRouletteRewardAmount(result?.data?.amount)
        checkRouletteEventCouponCount()
        if (result?.data?.reward === 1) {
          if (new Date(farmData?.puppyEndAt) >= new Date()) {
            let temp = { ...farmData }
            temp.puppyEndAt = new Date(
              new Date(farmData?.puppyEndAt).getTime() + 1000 * 60 * 60 * 24,
            )
            setFarmData(temp)
          } else if (new Date(farmData?.puppyEndAt) < new Date()) {
            let temp = { ...farmData }
            temp.puppyEndAt = new Date(
              new Date().getTime() + 1000 * 60 * 60 * 24,
            )
            setShowPuppy(true)
            setFarmData(temp)
          }
        }
        setTimeout(() => {
          if (result?.data?.reward === 5 || result?.data?.reward === 6) {
            let temp = { ...farmData }
            if (UserStore.waterExceeded) {
              temp.water += Math.round(result?.data?.amount * 0.5)
            } else {
              temp.water += result?.data?.amount
            }
            setFarmData(temp)
          }
        }, 7100)
      }
      setTimeout(() => {
        setRewardModal(true)
        setIsRolling(false)
        // setRouletteRewardNumber(0);
      }, 7100)
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        >
          <button
            style={{
              width: '100%',
              top: '8%',
              left: '20%',
              // right: "10%",
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
              zIndex: 1113,
              fontFamily: 'maplestory',
              fontSize: '5vw',
              textDecoration: 'underline',
            }}
            onClick={() => {
              if (!isRolling) {
                setGuideModal(true)
              }
            }}
          >
            유의사항
          </button>
          <button
            style={{
              width: '8%',
              top: '6%',
              right: '10%',
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
              zIndex: 1113,
              background: 'black',
            }}
            onClick={() => {
              if (!isRolling) {
                ModalStore.setIsModalOpen('basic')
              }
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              position: 'relative',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '30vw',
                top: '-32%',
                left: '35vw',
              }}
            >
              <img src='/rouletteV3/text.png' alt='' />
            </div>
            <div
              style={{
                color: 'white',
                fontSize: '4.3vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                marginTop: '5vw',
                position: 'absolute',
                width: '100%',
                lineHeight: '150%',
                top: '-18%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              룰렛 뽑기는 종료되었어요 <br />
              뽑기권 사용만 가능해요!
            </div>
            <div
              style={{
                marginTop: '10vw',
                marginLeft: '5%',
                width: '90vw',
                height: '90vw',
                transform: isRolling
                  ? `translate(0%, 0%) rotate(${
                      DEGREE_MAP[rouletteRewardNumber - 1]
                    }deg`
                  : `translate(0%, 0%)`,
                transition: isRolling
                  ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s'
                  : '',
              }}
            >
              <img src='/rouletteV3/roulette.png' alt='' />
            </div>
            <div
              style={{
                top: '-5vw',
                position: 'absolute',
                width: '15vw',
                left: '42.5%',
              }}
            >
              <img src='/rouletteV3/pointer.png' alt='' />
            </div>

            <button
              style={{
                top: '29.5vw',
                position: 'absolute',
                width: '30vw',
                left: '34.5%',
              }}
              onClick={() => {
                if (couponCount < 1) {
                  setInfoModal(true)
                  setTimeout(() => {
                    setInfoModal(false)
                  }, 3000)
                }
                if (!isRolling) {
                  startRoulette()
                }
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'white',
                  zIndex: 222,
                  position: 'absolute',
                  bottom: '8vw',
                  fontSize: '5vw',
                  // fontFamily: "maplestory",
                }}
              >
                {couponCount}번 가능
              </div>
              <img src='/rouletteV3/button.png' alt='' />
            </button>
            {new Date() <= new Date('2024-06-27T23:59:59.000Z') && (
              <>
                {inviteCount >= 3 ? (
                  <div
                    style={{
                      color: 'white',
                      fontSize: '5vw',
                      fontFamily: 'maplestory',
                      textAlign: 'center',
                      backgroundColor: '#C1C5CB',
                      padding: '5vw',
                      borderRadius: '50vw',
                      width: '80%',
                      marginLeft: '10%',
                      marginTop: '8%',
                    }}
                  >
                    내일 다시 가능해요
                  </div>
                ) : (
                  <div
                    style={{
                      color: '#402C24',
                      fontSize: '5vw',
                      fontFamily: 'maplestory',
                      textAlign: 'center',
                      background: inviteButtonPressed
                        ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                        : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                      boxShadow: inviteButtonPressed
                        ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                        : '',
                      //   width: "80%",
                      padding: '4vw',
                      borderRadius: '50vw',
                      width: '80%',
                      marginLeft: '10%',
                      marginTop: '4%',
                    }}
                    onPointerDown={() => {
                      if (!isRolling) {
                        setInviteButtonPressed(true)
                      }
                    }}
                    onPointerCancel={() => {
                      setInviteButtonPressed(false)
                    }}
                    onPointerUp={() => {
                      setInviteButtonPressed(false)
                    }}
                    onClick={() => {
                      if (!isRolling && !infoModal) {
                        shareKakao()
                      }
                    }}
                  >
                    친구 초대하고 뽑기권 1개 받기
                  </div>
                )}
              </>
            )}

            {new Date() <= new Date('2024-06-27T23:59:59.000Z') && (
              <div
                style={{
                  color: 'white',
                  fontSize: '4.5vw',
                  fontFamily: 'maplestory',
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '2vw',
                }}
              >
                {inviteCount >= 3 ? (
                  <> 오늘 초대 가능한 횟수를 다 채웠어요</>
                ) : (
                  <> 오늘 {3 - inviteCount}명 더 초대할 수 있어요</>
                )}
              </div>
            )}
          </div>

          {infoModal && (
            <div
              style={{
                flex: 1,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: 2000,
                width: '80%',
                height: '30vw',
                background: 'rgba(0,0,0,0.8)',
                borderRadius: '4vw',
                fontFamily: 'maplestory',
                fontSize: '4.5vw',
                textAlign: 'center',
                lineHeight: '180%',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              뽑기권이 부족해요 <br />
              친구를 초대해 뽑기권을 받을 수 있어요
            </div>
          )}
          {rewardModal && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 3000,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  background: 'white',
                  width: '50%',
                  height: '55vw',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  borderRadius: '4vw',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    transform: 'translate(-50%,-50%)',
                    left: '50%',
                    top: '15%',
                    position: 'absolute',
                    color: 'black',
                    textAlign: 'center',
                    fontSize: '5vw',
                  }}
                >
                  {REWARD_NAME[rouletteRewardNumber - 1]} 당첨!
                </div>
                <img
                  style={{
                    width: '45%',
                    transform: 'translate(-50%,-50%)',
                    left: '50%',
                    top: '45%',
                    position: 'absolute',
                  }}
                  alt=''
                  src={`/rouletteV3/${rouletteRewardNumber}.png`}
                />
                <button
                  style={{
                    width: '50%',
                    background:
                      'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    fontFamily: 'maplestory',
                    color: 'black',
                    fontSize: '4vw',
                    borderRadius: '30px',
                    padding: '4vw',
                    position: 'absolute',
                    left: '25%',
                    bottom: '4vw',
                  }}
                  onClick={() => {
                    setRewardModal(false)
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          )}

          {guideModal && (
            <>
              <div
                style={{
                  flex: 1,
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  zIndex: 5004,
                  width: '100%',
                  height: '100vh',
                  backgroundColor: 'rgba(0,0,0,0.8)',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2000,
                    width: '80%',
                    background: 'white',
                    borderRadius: 20,
                    fontFamily: 'maplestory',
                    fontSize: '4vw',
                    padding: '6vw',
                    paddingTop: '8vw',
                    lineHeight: '170%',
                    paddingBottom: '8vw',
                  }}
                >
                  <button
                    style={{
                      width: '10%',
                      top: '4vw',
                      right: '6%',
                      zIndex: 2001,
                      position: 'absolute',
                    }}
                    onClick={() => {
                      setGuideModal(false)
                    }}
                  >
                    <img
                      style={{
                        zIndex: 101,
                      }}
                      src='/icon/modalCloseIcon.png'
                      alt=''
                    />
                  </button>
                  <div
                    style={{
                      textAlign: 'center',
                      color: 'black',
                    }}
                  >
                    안내사항
                  </div>
                  <div style={{ height: '3vw' }} />
                  <div
                    style={{
                      color: 'black',
                    }}
                  >
                    - 룰렛 뽑기는 종료되었어요
                    <br />- 뽑기권을 다 사용하기 전까지는 계속해서 이용 가능해요
                    <br />- 뽑기권을 모두 사용하면 룰렛 뽑기가 사라져요
                    <br />
                    - 친구를 초대하면 뽑기권을 받을 수 있어요 <br />- 이미
                    올팜을 하고 있는 친구를 초대하면 뽑기권 1개를 받아요
                    <br />- 올팜을 안 해본 친구를 초대하면 뽑기권 {rewardAmount}
                    개를 받아요
                    <br />- 하루 최대 3명의 친구를 초대할 수 있어요
                    <br />- 하루 최대 3명의 친구 초대를 받을 수 있어요
                    <br />- 3명을 초과해서 초대하면 초대권은 지급되지 않아요
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  },
)

export default RouletteModal
