import React, { useEffect, useRef, useCallback } from 'react'

const CustomScratchCard = ({ children, width, height, onComplete }) => {
  const canvasRef = useRef(null)
  const contextRef = useRef(null)
  const lastPoint = useRef(null)
  const erasedList = useRef([])
  const isDrawing = useRef(false)
  const thresholdOfEraseCount = useRef(0)
  const isRevealed = useRef(false)

  const ERASE_RADIUS = 30
  const ERASE_DISTANCE = ERASE_RADIUS / 2

  // Canvas 초기화
  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const dpr = window.devicePixelRatio

    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
    canvas.width = width * dpr
    canvas.height = height * dpr
    context.scale(dpr, dpr)

    context.strokeStyle = '#999'
    context.fillStyle = '#fff'
    context.beginPath()
    context.roundRect(0, 0, width, height, 8)
    context.stroke()
    context.fill()

    context.font = '20px sans-serif'
    context.fillStyle = '#000'
    context.textAlign = 'center'
    context.textBaseline = 'middle'

    const col = Math.ceil(width / (ERASE_RADIUS * 2 + ERASE_DISTANCE))
    const row = Math.ceil(height / (ERASE_RADIUS * 2 + ERASE_DISTANCE))
    thresholdOfEraseCount.current = (col * row * 9) / 10

    contextRef.current = context
  }, [])

  // 유틸리티 함수들
  const drawTransparentCircle = useCallback((x, y) => {
    const context = contextRef.current
    context.save()
    context.globalCompositeOperation = 'destination-out'
    context.beginPath()
    context.arc(x, y, ERASE_RADIUS, 0, 2 * Math.PI, false)
    context.fill()
    context.closePath()
    context.restore()

    const checkList = erasedList.current.filter(({ x: posX, y: posY }) => {
      const distX = posX - x
      const distY = posY - y
      return (
        Math.sqrt(distX * distX + distY * distY) < ERASE_RADIUS + ERASE_DISTANCE
      )
    })

    if (!checkList.length) {
      erasedList.current.push({ x, y })
    }
  }, [])

  const angleBetween = useCallback((point1, point2) => {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y)
  }, [])

  // 이벤트 핸들러들
  const handleDrawing = useCallback(
    (x, y) => {
      if (!isDrawing.current) return

      const currentPoint = { x, y }
      if (lastPoint.current) {
        const distance = Math.sqrt(
          Math.pow(currentPoint.x - lastPoint.current.x, 2) +
            Math.pow(currentPoint.y - lastPoint.current.y, 2),
        )

        const angle = angleBetween(lastPoint.current, currentPoint)

        for (let i = 0; i < distance; i += 5) {
          const drawX = lastPoint.current.x + Math.sin(angle) * i
          const drawY = lastPoint.current.y + Math.cos(angle) * i

          if (erasedList.current.length < thresholdOfEraseCount.current) {
            drawTransparentCircle(drawX, drawY)
          } else if (!isRevealed.current) {
            contextRef.current.clearRect(0, 0, width, height)
            isRevealed.current = true
            onComplete()
          }
        }
      }

      lastPoint.current = currentPoint
    },
    [angleBetween, drawTransparentCircle, onComplete],
  )

  // 이벤트 리스너 설정
  useEffect(() => {
    const canvas = canvasRef.current
    const { top: canvasTop, left: canvasLeft } = canvas.getBoundingClientRect()

    const handleDrawingStart = (event) => {
      isDrawing.current = true
      const { offsetX, offsetY } = event
      lastPoint.current = { x: offsetX, y: offsetY }
    }

    const handleMouseMove = (event) => {
      event.preventDefault()
      const { offsetX, offsetY } = event
      handleDrawing(offsetX, offsetY)
    }

    const handleTouchMove = (event) => {
      event.preventDefault()
      const { clientX, clientY } = event.changedTouches[0]
      handleDrawing(clientX - canvasLeft, clientY - canvasTop)
    }

    const handleDrawingEnd = () => {
      isDrawing.current = false
      lastPoint.current = null
    }

    canvas.addEventListener('mousedown', handleDrawingStart)
    canvas.addEventListener('touchstart', handleDrawingStart)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('touchmove', handleTouchMove)
    canvas.addEventListener('mouseup', handleDrawingEnd)
    canvas.addEventListener('touchend', handleDrawingEnd)

    return () => {
      canvas.removeEventListener('mousedown', handleDrawingStart)
      canvas.removeEventListener('touchstart', handleDrawingStart)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('touchmove', handleTouchMove)
      canvas.removeEventListener('mouseup', handleDrawingEnd)
      canvas.removeEventListener('touchend', handleDrawingEnd)
    }
  }, [handleDrawing])

  return (
    <div className='relative'>
      <div
        className={`absolute top-0 left-0 flex items-center justify-center text-3xl font-bold bg-[#E3F3FF] rounded-2xl`}
        style={{
          width,
          height,
        }}
      >
        {children}
      </div>
      <canvas ref={canvasRef} className='relative rounded-2xl'>
      </canvas>
    </div>
  )
}

export default CustomScratchCard
